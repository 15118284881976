import React from "react";
import { MutatingDots } from "react-loader-spinner";
import "./style.scss";

function PageLoader() {
  return (
    <div className="loader-container">
      <MutatingDots
        height="100"
        width="100"
        color="#7a936e"
        secondaryColor="#7a936e"
        radius="12.5"
        ariaLabel="mutating-dots-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
}
export default PageLoader;

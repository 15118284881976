import React, { useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { ref, set, update, push } from "firebase/database";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import { getLocalText } from "../locales/i18nextInit";
import { rdb, tables } from "../services/config";
import moment from "moment";

function AddMapModel(props) {
  const {
    show,
    edit,
    names,
    handleDateTime,
    handleClose,
    handleClearPipelineInput,
    handleNameInput,
    timeLimit,
    dateLimit,
  } = props;
  const [nameError, setNameError] = useState("");

  const handleChange = (e, type) => {
    handleNameInput(e);
    setNameError("");
  };

  const handleAddEdit = () => {
    if (!names.trim()) {
      setNameError(getLocalText("ErrorMessages.emptyPipelineName"));
    } else {
      setNameError("");
      handleClearPipelineInput();
      var obj = {
        createdAt: edit ? edit.item[1].createdAt : new Date().getTime(),
        label: names,
        label_lower: names.toLowerCase(),
        pipelineId: edit
          ? edit.item[1].pipelineId
          : Math.floor(100000000 + Math.random() * 900000000),
        updatedAt: new Date().getTime(),
        status: "Active",
        time_limit: timeLimit || "",
        date_limit: dateLimit || "",
      };
      if (edit) {
        // Update
        obj = { ...edit.item[1], ...obj };
        var updates = {};
        // const newTodoKey = push(child(ref(rdb), tables.projects)).key; // generate new key
        updates[tables.projects + edit.item[0]] = obj;
        update(ref(rdb), updates);
      } else {
        // Add
        const projectsGroups = ref(rdb, tables.projects);
        var newPostRef = push(projectsGroups);
        set(newPostRef, obj);
      }
      handleClose();
    }
  };

  const inputRender = (itemName, value, title, error) => {
    return (
      <Col lg={12}>
        <Form.Group className="mb-3">
          <Form.Label className="font-bold text-base">{title}</Form.Label>
          <Form.Control
            type="text"
            name={itemName}
            onChange={(e) => handleChange(e, itemName)}
            value={value}
            isInvalid={error}
            isValid={itemName}
          />
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </Form.Group>
      </Col>
    );
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="md"
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-xl">
            {edit
              ? getLocalText("Home.editCard")
              : getLocalText("Home.addNewCard")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              {inputRender("name", names, getLocalText("Home.name"), nameError)}
              {/* <Col xs={12}>
                <Form.Group>
                  <Form.Label className="font-bold text-base">Date</Form.Label>
                  <Form.Control type="email" placeholder="Enter email" />
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group>
                  <Form.Label className="font-bold text-base">Date</Form.Label>
                  <Form.Control type="email" placeholder="Enter email" />
                </Form.Group>
              </Col> */}
              <Col xs={6}>
                <Form.Group>
                  <Form.Label className="font-bold text-base">Date</Form.Label>
                  <Flatpickr
                    className="form-control"
                    options={{
                      dateFormat: "Y-m-d",
                      _minDate: new Date(),
                      _minTime: new Date(),
                    }}
                    placeholder="YYYY-MM-DD"
                    value={dateLimit}
                    onChange={(value) => {
                      handleDateTime(
                        new Date(value[0]).getTime()
                        // moment(new Date(value[0]).toLocaleDateString()).format(
                        //   "YYYY-MM-DD"
                        // )
                      );
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group>
                  <Form.Label className="font-bold text-base">Time</Form.Label>
                  <Flatpickr
                    className="form-control"
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      time_24hr: true,
                      // _minDate: new Date(),
                      // _minTime: new Date(),
                    }}
                    onChange={(value) => {
                      handleDateTime(
                        new Date(value[0]).getTime(),
                        // new Date(value[0]).toLocaleTimeString({
                        //   hour: "numeric",
                        //   minute: "numeric",
                        //   hour12: false,
                        // }),
                        "time"
                      );
                    }}
                    placeholder="00:00"
                    value={timeLimit}
                  />
                </Form.Group>
              </Col>
              {/* {inputRender("phase", phase, getLocalText("Home.phase"))} */}

              {/* <Col lg={12}>
                <Form.Group className="mb-3">
                  <Form.Label className="font-bold text-base">
                    {getLocalText("Home.pipelineHubSpot")}
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    className="px-2 text-base select-placeholder"
                    defaultValue={"DEFAULT"}
                  >
                    <option value="DEFAULT" disabled>
                      {getLocalText("Home.chooseFromChoice")}
                    </option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </Form.Group>
              </Col> */}
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className="justify-content-start">
          <Button variant="green" onClick={handleAddEdit}>
            {edit ? getLocalText("Home.edit") : getLocalText("Home.add")}
          </Button>
          <Button
            variant="outline-green"
            onClick={() => {
              setNameError("");
              handleClose();
            }}
          >
            {getLocalText("Home.cancel")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddMapModel;

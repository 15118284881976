import React, { useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { ref, set, update, push } from "firebase/database";
import { getLocalText } from "../../locales/i18nextInit";
import { rdb, tables } from "../../services/config";

function AddNewUser(props) {
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [telephoneError, setTelephoneError] = useState("");
  const [categoryError, setCategoryError] = useState("");

  const {
    name,
    email,
    telephone,
    setName,
    category,
    setTelephone,
    setEmail,
    setCategory,
    handleModalInput,
    handleClose,
    show,
    handleClearInputs,
    edit,
    location,
    handleAddUpdateData,
  } = props;

  const handleInput = (e, type) => {
    type(e.target.value);
    handleModalInput(e, type);
    clearErrors();
  };

  const clearErrors = () => {
    setNameError("");
    setEmailError("");
    setTelephoneError("");
    setCategoryError("");
  };

  const renderInput = (itemName, value, title, error, setType) => {
    return (
      <Col lg={6}>
        <Form.Group className="mb-3">
          <Form.Label className="font-bold text-base">{title}</Form.Label>
          <Form.Control
            type="text"
            name={itemName}
            value={value}
            onChange={(e) => handleInput(e, setType)}
            isInvalid={error}
            isValid={itemName}
          />
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </Form.Group>
      </Col>
    );
  };

  const handleAddEditContact = () => {
    if (!name.trim()) {
      setNameError("Enter name");
    } else if (!email.trim()) {
      setEmailError("Enter email");
    } else if (!telephone.trim()) {
      setTelephoneError("Enter telephone");
    } else if (category === "DEFAULT") {
      setCategoryError("Select category");
    } else {
      var vid = new Date().getTime();
      const timeNow = new Date().getTime();
      var obj = {
        name: name || "",
        lastname: "",
        name_lower: name?.toLowerCase() || "",
        email: email || "",
        phoneNumber: telephone || "",
        category: category || "",
        createdDate: edit
          ? edit?.item[1]?.createdDate
          : new Date().getTime() || "",
        vid: edit ? edit?.item[1].vid : vid,
        type: "personal",
        lastmodifieddate: timeNow,
      };

      if (edit) {
        obj = { ...edit?.item[1], ...obj };
        let updates = {};
        updates[tables.contacts + edit.item[0]] = obj;
        update(ref(rdb), updates);
        handleAddUpdateData([edit?.item[0], obj], "update");
      } else {
        const contactGroups = ref(rdb, tables.contacts);
        var newPostRef = push(contactGroups);
        set(newPostRef, obj);
        handleAddUpdateData([newPostRef.key, obj]);
        const locationStateData = location?.state?.item;
        const isCompanyData = location?.state?.isCompanyData;
        if (isCompanyData) {
          let objCompany = {
            ...locationStateData,
            contactVids: locationStateData?.contactVids
              ? [...locationStateData?.contactVids, vid]
              : [vid],
          };
          let updates = {};
          updates[tables.companies + location?.state?.key] = objCompany;
          update(ref(rdb), updates);
        } else if (locationStateData) {
          let objDeal = {
            ...locationStateData,
            associations: {
              associatedCompanyIds:
                locationStateData?.associations?.associatedCompanyIds || [],
              // associatedCompanyIds:
              //   locationStateData?.associations?.associatedCompanyIds,
              associatedVids: locationStateData?.associations?.associatedVids
                ? [...locationStateData?.associations?.associatedVids, vid]
                : [vid],
              // associatedDealIds:
              //   locationStateData?.associations?.associatedDealIds,
              // associatedTicketIds:
              //   locationStateData?.associations?.associatedTicketIds,
            },
          };
          let updates = {};
          updates[tables.deals + location?.state?.key] = objDeal;
          update(ref(rdb), updates);
        }
      }
      handleClearInputs();
      clearErrors();
      handleClose();
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-xl">
            {edit
              ? getLocalText("Users.editNewUser")
              : getLocalText("Users.addNewUser")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              {renderInput(
                "name",
                name,
                getLocalText("Home.name"),
                nameError,
                setName
              )}

              {renderInput(
                "telephone",
                telephone,
                getLocalText("Users.phoneNumber"),
                telephoneError,
                setTelephone
              )}

              {renderInput(
                "email",
                email,
                getLocalText("Auth.email"),
                emailError,
                setEmail
              )}

              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="font-bold text-base">
                    {getLocalText("Users.category")}
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    className="px-2 text-base select-placeholder"
                    value={category}
                    isInvalid={categoryError}
                    isValid={category}
                    onChange={(e) => handleInput(e, setCategory)}
                  >
                    <option disabled value="DEFAULT">
                      {getLocalText("Users.selectCategory")}
                    </option>
                    <option value="Admin">Admin</option>
                    <option value="Normal">Normal</option>
                    <option value="Blocked">Blocked</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className="justify-content-start">
          <Button variant="green" onClick={handleAddEditContact}>
            {edit ? getLocalText("Home.edit") : getLocalText("Home.add")}
          </Button>
          <Button
            variant="outline-green"
            onClick={() => {
              handleClose();
              handleClearInputs();
              clearErrors();
            }}
          >
            {getLocalText("Home.cancel")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddNewUser;

import React, { useEffect, useState } from "react";
import { Col, Row, Container, Form, Button } from "react-bootstrap";
import { confirmAlert } from "../customPlugin/react-confirm-alert";
import "../customPlugin/react-confirm-alert/src/react-confirm-alert.css";
import {
  ref,
  set,
  onValue,
  query,
  limitToLast,
  limitToFirst,
  orderByKey,
  startAfter,
  endBefore,
  orderByChild,
  equalTo,
  // get,
  // child,
  push,
  update,
} from "firebase/database";
import { GrSync } from "react-icons/gr";
import { IoClose } from "react-icons/io5";
import {
  Header,
  HomeTable,
  TablePagination,
  Footer,
  AddMapModel,
  // PageLoader,
  SyncLoader,
  DeleteConfirmModal,
} from "../components";
// import pine_hill_company from "../assets/pine_hill_contact_latest.json";
// import {
//   PIPELINE_LIST,
//   MAP_DATA,
//   GET_ALL_CONTACT,
//   GET_CONTACT_OF_COMPANY,
//   GET_ALL_COMPANY,
//   GET_ENGAGEMENT,
//   headersKey,
// } from "../services/hubspotConfig";
import { getLocalText } from "../locales/i18nextInit";
import { rdb, tables } from "../services/config";
// import { hubspotToFirebaseSync } from "../services";

const projectRef = ref(rdb, tables.projects);
// const dealsRef = ref(rdb, tables.deals);
// const contactRef = ref(rdb, tables.contacts);
// const companiesRef = ref(rdb, tables.companies);

var limit = 20;
// const dbProjectRef = child(ref(rdb), tables.projects);

function Home() {
  const [projectsData, setProjectsData] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [dealsData, setDealsData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [pipelineName, setPipelineName] = useState("");
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
  const [lastVisible, setLastVisible] = useState();
  const [previousVisible, setPreviousVisible] = useState();
  const [lastItem, setLastItem] = useState();
  const [firstItem, setFirstItem] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [timeLimit, setTimeLimit] = useState();
  const [dateLimit, setDateLimit] = useState();

  // useEffect(() => {
  //   setLoading(true);
  //   async function fetchData() {
  //     const starCountRef = ref(rdb, tables.projects);
  //     onValue(starCountRef, (snapshot) => {
  //       const newProjectsData = snapshot.val();
  //       const projects = Object.entries(newProjectsData);
  //       setProjectsData(projects);
  //     });
  //   }
  //   fetchData();
  // }, []);

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      getFirstPage();
      const starCountRefLast = query(
        projectRef,
        orderByKey(), // orderByChild("label"),
        limitToLast(1)
      );
      setData(starCountRefLast, "last");

      const starCountRefFirst = query(
        projectRef,
        orderByKey(),
        limitToFirst(1)
      );
      setData(starCountRefFirst, "first");
      const starCountRef = ref(rdb, tables.deals);
      onValue(
        starCountRef,
        (snapshot) => {
          const newDealsData = snapshot.val() || {};
          const deals = Object.entries(newDealsData) || [];
          setDealsData(deals);
          setLoading(false);
        },
        { onlyOnce: true }
      );
      // get(query(dbProjectRef, orderByKey(), limitToFirst(limit)))
      //   .then((snapshot) => {
      //     if (snapshot.exists()) {
      //       setData(snapshot);
      //     }
      //   })
      //   .catch((error) => {
      //   });
    }
    fetchData();
  }, []);

  const handleNext = () => {
    if (lastItem !== projectsData[projectsData.length - 1][0]) {
      const starCountRef = query(
        projectRef,
        orderByKey(),
        limitToFirst(limit),
        startAfter(lastVisible)
      );
      setData(starCountRef);
      setCurrentPage(currentPage + 1);
      // get(
      //   query(
      //     dbProjectRef,
      //     orderByKey(),
      //     limitToFirst(limit),
      //     startAfter(lastVisible)
      //   )
      // )
      //   .then((snapshot) => {
      //     if (snapshot.exists()) {
      //       setData(snapshot);
      //     }
      //   })
      //   .catch((error) => {
      //   });
    }
  };

  const handlePrevious = () => {
    if (firstItem !== projectsData[0][0]) {
      const starCountRef = query(
        projectRef,
        orderByKey(),
        limitToLast(limit),
        endBefore(previousVisible)
      );
      setData(starCountRef);
      setCurrentPage(currentPage - 1);
      // get(
      //   query(
      //     dbProjectRef,
      //     orderByKey(),
      //     limitToLast(limit),
      //     endBefore(previousVisible)
      //   )
      // )
      //   .then((snapshot) => {
      //     if (snapshot.exists()) {
      //       setData(snapshot);
      //     }
      //   })
      //   .catch((error) => {
      //   });
    }
  };

  // const setData = (snapshot) => {
  //   const newProjectsData = snapshot.val() || [];
  //   const projects = Object.entries(newProjectsData);
  //   setLastVisible(projects[projects.length - 1][0]);
  //   setPreviousVisible(projects[0][0]);
  //   setProjectsData(projects);
  // };

  const setData = (starCountRef, itemCheck = "") => {
    onValue(starCountRef, (snapshot) => {
      const newProjectsData = snapshot.val() || {};
      const projects = Object.entries(newProjectsData) || [];
      setIsSearch(false);
      setSearchValue("");
      if (projects.length > 0) {
        if (itemCheck === "last") {
          setLastItem(projects[0][0]);
        } else if (itemCheck === "first") {
          setFirstItem(projects[0][0]);
        } else {
          setProjectsData(projects);
          setLastVisible(projects[projects.length - 1][0]);
          setPreviousVisible(projects[0][0]);
        }
      }
    });
  };

  // useEffect(() => {
  //   async function fetchData() {
  //     const starCountRef = ref(rdb, tables.deals);
  //     onValue(starCountRef, (snapshot) => {
  //       const newDealsData = snapshot.val() || {};
  //       const deals = Object.entries(newDealsData) || [];
  //       setDealsData(deals);
  //       // setLoading(false);
  //     });
  //   }
  //   fetchData();
  // }, []);

  const handleAddModalClose = () => {
    setShowAddModal(false);
    setEditItem(null);
    handleClearPipelineInput();
  };
  const handleAddModalShow = () => setShowAddModal(true);

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    if (!e.target.value) {
      setIsSearch(false);
    }
    if (isSearch) {
      searchResultHandle(e.target.value, "val");
    }
  };

  const searchResultHandle = (val, type) => {
    var searchKey = type ? val : searchValue;
    if (searchKey) {
      const starCountRef = query(
        projectRef,
        orderByChild("label_lower"),
        equalTo(searchKey.toLowerCase())
      );
      setValue(starCountRef);
    } else {
      getFirstPage();
    }
  };

  const setValue = (starCountRef) => {
    onValue(
      starCountRef,
      (snapshot) => {
        const newProjectsData = snapshot.val() || {};
        const projects = Object.entries(newProjectsData) || [];
        setProjectsData(projects);
      },
      { onlyOnce: true }
    );
  };

  const getFirstPage = () => {
    const starCountRef = query(projectRef, orderByKey(), limitToFirst(limit));
    setData(starCountRef);
  };

  const handleSearchButton = (e) => {
    e.preventDefault();
    searchResultHandle();
    setIsSearch(searchValue ? true : false);
  };

  const clearSearch = () => {
    setIsSearch(false);
    setSearchValue("");
    getFirstPage();
  };

  const handleEdit = (item, index, key) => {
    setEditItem({ item, index, key });
    setPipelineName(item[1]?.label);
    setTimeLimit(item[1]?.time_limit);
    setDateLimit(item[1]?.date_limit);
    setShowAddModal(true);
  };

  const handlePipelineNameInput = (e) => {
    setPipelineName(e.target.value);
  };

  const handleDateTimeInput = (value, type) => {
    if (type === "time") {
      setTimeLimit(value);
    } else {
      setDateLimit(value);
    }
  };

  const handleClearPipelineInput = () => {
    setPipelineName("");
  };

  const handleDelete = (item, index, key) => {
    setIsDeleteConfirm(true);
    setEditItem({ item, index, key });
  };

  const handleConfirmDelete = () => {
    setIsDeleteConfirm(false);
    set(ref(rdb, `${tables.projects}${editItem.item[0]}`), null);
    setEditItem(null);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteConfirm(false);
    setEditItem(null);
  };

  // const hubspotToFirebaseSync = () => {
  //   // // handleDealsData();
  //   // // handleContactData();
  //   // // handleCompanyData();
  //   const options = {
  //     title: "Êtes-vous sûr?",
  //     message:
  //       "En cliquant sur ce bouton, vous synchroniserez vos données Hubspot avec votre tableau de bord",
  //     buttons: [
  //       {
  //         label: getLocalText("Home.confirm"),
  //         onClick: () => {
  //           setLoading(true);
  //           handlePipeline();
  //         },
  //       },
  //       {
  //         label: getLocalText("Home.cancel"),
  //         onClick: () => {},
  //       },
  //     ],
  //     closeOnEscape: true,
  //     closeOnClickOutside: true,
  //   };
  //   confirmAlert(options);
  // };

  // const handlePipeline = () => {
  //   fetch(PIPELINE_LIST, { headers: headersKey })
  //     .then((res) => res.json())
  //     .then(
  //       (res) => {
  //         handleDealsData();
  //         res.results.forEach((element, index) => {
  //           const queryData = query(
  //             projectRef,
  //             orderByChild("pipelineId"),
  //             equalTo(element.pipelineId)
  //           );
  //           onValue(
  //             queryData,
  //             (snapshot) => {
  //               const val = snapshot.val() || {};
  //               const data = Object.entries(val) || [];
  //               var stages = element.stages.map((d) => {
  //                 return { label: d.label, stageId: d.stageId };
  //               });
  //               if (snapshot.exists()) {
  //                 // update
  //                 let obj = {
  //                   ...data[0][1],
  //                   label: element.label,
  //                   label_lower: element.label?.toLowerCase() || "",
  //                   updatedAt: element.updatedAt,
  //                   pipelineIdHubspot: element.pipelineId || "",
  //                   stages: stages,
  //                 };
  //                 let updates = {};
  //                 updates[tables.projects + data[0][0]] = obj;
  //                 update(ref(rdb), updates);
  //               } else {
  //                 // add
  //                 let obj = {
  //                   label: element.label || "",
  //                   label_lower: element.label?.toLowerCase() || "",
  //                   createdAt: element.createdAt || "",
  //                   // displayOrder: item.displayOrder,
  //                   // objectType: item.objectType,
  //                   pipelineId: element.pipelineId || "",
  //                   pipelineIdHubspot: element.pipelineId || "",
  //                   updatedAt: element.updatedAt || "",
  //                   status: "Active",
  //                   stages: stages,
  //                   // objectTypeId: element.objectTypeId,
  //                 };
  //                 var newPipelineRef = push(projectRef);
  //                 set(newPipelineRef, obj);
  //               }
  //             },
  //             { onlyOnce: true }
  //           );
  //         });
  //       },
  //       (error) => {}
  //     );
  // };

  // const handleDealsData = async () => {
  //   // await getAllDeals();
  //   await getAllDealsData();
  // };

  // const getAllDealsData = () => {
  //   const recur = async (offset = 0) => {
  //     setLoading(true);
  //     await fetch(`${MAP_DATA}&offset=${offset}`, { headers: headersKey })
  //       .then((res) => res.json())
  //       .then(
  //         async (result) => {
  //           result.deals.forEach(async (element, index) => {
  //             const queryData = query(
  //               dealsRef,
  //               orderByChild("dealId"),
  //               equalTo(element.dealId)
  //             );
  //             var engagementData = [];
  //             const recurEngage = async (offset = 0) => {
  //               fetch(`${GET_ENGAGEMENT(element.dealId)}&offset=${offset}`, {
  //                 headers: headersKey,
  //               })
  //                 .then((res) => res.json())
  //                 .then((res) => {
  //                   engagementData = res.results
  //                     ? [...engagementData, ...res.results]
  //                     : engagementData;

  //                   if (res.hasMore) {
  //                     recurEngage(res.offset);
  //                   } else {
  //                     var notesData = engagementData
  //                       .filter((d) => d.engagement.type === "NOTE")
  //                       .map((d) => {
  //                         return {
  //                           title: d.engagement.bodyPreview || "",
  //                           hubspotId: d.engagement.id || "",
  //                         };
  //                       });

  //                     onValue(
  //                       queryData,
  //                       (snapshot) => {
  //                         const val = snapshot.val() || {};
  //                         const data = Object.entries(val) || [];
  //                         let firebaseNotes =
  //                           data.length > 0 && data[0][1]?.notes?.length > 0
  //                             ? data[0][1].notes.map((d) => d.hubspotId)
  //                             : [];

  //                         notesData = notesData.filter(
  //                           (d) => !firebaseNotes.includes(d.hubspotId)
  //                         );
  //                         const { properties } = element;
  //                         const firebaseObjProperties =
  //                           data.length > 0 && data[0][1]?.properties;

  //                         const timeNow = new Date().getTime();
  //                         const propsVal = {
  //                           amount: {
  //                             value:
  //                               (firebaseObjProperties?.amount?.timestamp >
  //                               properties?.amount?.timestamp
  //                                 ? firebaseObjProperties?.amount?.value
  //                                 : properties?.amount?.value) || "",
  //                             timestamp: timeNow,
  //                             // properties?.amount?.timestamp || timeNow,
  //                           },
  //                           dealname: {
  //                             value:
  //                               (firebaseObjProperties?.dealname?.timestamp >
  //                               properties?.dealname?.timestamp
  //                                 ? firebaseObjProperties?.dealname?.value
  //                                 : properties?.dealname?.value) || "",
  //                             timestamp: timeNow,
  //                           },
  //                           dealstage: {
  //                             value:
  //                               (firebaseObjProperties?.dealstage?.timestamp >
  //                               properties?.dealstage?.timestamp
  //                                 ? firebaseObjProperties?.dealstage?.value
  //                                 : properties?.dealstage?.value) || "",
  //                             timestamp: timeNow,
  //                           },
  //                           frais_d_infrastructure: {
  //                             value:
  //                               (firebaseObjProperties?.frais_d_infrastructure
  //                                 ?.timestamp >
  //                               properties?.frais_d_infrastructure?.timestamp
  //                                 ? firebaseObjProperties
  //                                     ?.frais_d_infrastructure?.value
  //                                 : properties?.frais_d_infrastructure
  //                                     ?.value) || "",
  //                             timestamp: timeNow,
  //                           },
  //                           identification_du_lot_ph: {
  //                             value:
  //                               (firebaseObjProperties?.identification_du_lot_ph
  //                                 ?.timestamp >
  //                               properties?.identification_du_lot_ph?.timestamp
  //                                 ? firebaseObjProperties
  //                                     ?.identification_du_lot_ph?.value
  //                                 : properties?.identification_du_lot_ph
  //                                     ?.value) || "",
  //                             timestamp: timeNow,
  //                           },
  //                           nombre_de_pi2: {
  //                             value:
  //                               (firebaseObjProperties?.nombre_de_pi2
  //                                 ?.timestamp >
  //                               properties?.nombre_de_pi2?.timestamp
  //                                 ? firebaseObjProperties?.nombre_de_pi2?.value
  //                                 : properties?.nombre_de_pi2?.value) || "",
  //                             timestamp: timeNow,
  //                           },
  //                           pipeline: {
  //                             value:
  //                               (firebaseObjProperties?.pipeline?.timestamp >
  //                               properties?.pipeline?.timestamp
  //                                 ? firebaseObjProperties?.pipeline?.value
  //                                 : properties?.pipeline?.value) || "",
  //                             timestamp: timeNow,
  //                           },
  //                           prix_au_pi2: {
  //                             value:
  //                               (firebaseObjProperties?.prix_au_pi2?.timestamp >
  //                               properties?.prix_au_pi2?.timestamp
  //                                 ? firebaseObjProperties?.prix_au_pi2?.value
  //                                 : properties?.prix_au_pi2?.value) || "",
  //                             timestamp: timeNow,
  //                           },
  //                           statut_des_promesses_d_achat: {
  //                             value:
  //                               (firebaseObjProperties
  //                                 ?.statut_des_promesses_d_achat?.timestamp >
  //                               properties?.statut_des_promesses_d_achat
  //                                 ?.timestamp
  //                                 ? firebaseObjProperties
  //                                     ?.statut_des_promesses_d_achat?.value
  //                                 : properties?.statut_des_promesses_d_achat
  //                                     ?.value) || "",
  //                             timestamp: timeNow,
  //                           },
  //                           depot_necessaire: {
  //                             value:
  //                               (firebaseObjProperties?.depot_necessaire
  //                                 ?.timestamp >
  //                               properties?.depot_necessaire?.timestamp
  //                                 ? firebaseObjProperties?.depot_necessaire
  //                                     ?.value
  //                                 : properties?.depot_necessaire?.value) || "",
  //                             timestamp: timeNow,
  //                           },
  //                           description: {
  //                             value:
  //                               (firebaseObjProperties?.description?.timestamp >
  //                               properties?.description?.timestamp
  //                                 ? firebaseObjProperties?.description?.value
  //                                 : properties?.description?.value) || "",
  //                             timestamp: timeNow,
  //                           },
  //                         };
  //                         if (snapshot.exists()) {
  //                           // update
  //                           let firebaseAssociations = data[0][1]?.associations,
  //                             hubspotAssociations = element?.associations;
  //                           let firebaseCompanyIds =
  //                               firebaseAssociations?.associatedCompanyIds ||
  //                               [],
  //                             hubspotCompanyIds =
  //                               hubspotAssociations?.associatedCompanyIds || [],
  //                             firebaseContactIds =
  //                               firebaseAssociations?.associatedVids || [],
  //                             hubspotContactIds =
  //                               hubspotAssociations?.associatedVids || [];
  //                           let obj = {
  //                             ...data[0][1],
  //                             notes:
  //                               (data[0][1]?.notes &&
  //                               data[0][1]?.notes.length > 0
  //                                 ? [...data[0][1]?.notes, ...notesData]
  //                                 : notesData) || [],
  //                             // dealId: element.dealId,
  //                             dealIdHubspot: element.dealId,
  //                             portalId: element.portalId,
  //                             pipelineId: propsVal.pipeline.value,
  //                             dealname_lower:
  //                               properties?.dealname?.value?.toLowerCase() ||
  //                               "",
  //                             properties: propsVal,
  //                             associations: {
  //                               associatedCompanyIds: firebaseCompanyIds.concat(
  //                                 hubspotCompanyIds.filter(
  //                                   (item) =>
  //                                     firebaseCompanyIds.indexOf(item) < 0
  //                                 )
  //                               ),
  //                               associatedVids: firebaseContactIds.concat(
  //                                 hubspotContactIds.filter(
  //                                   (item) =>
  //                                     firebaseContactIds.indexOf(item) < 0
  //                                 )
  //                               ),
  //                               associatedDealIds:
  //                                 hubspotAssociations.associatedDealIds,
  //                               associatedTicketIds:
  //                                 hubspotAssociations.associatedTicketIds,
  //                             },
  //                           };

  //                           let updates = {};
  //                           updates[tables.deals + data[0][0]] = obj;
  //                           update(ref(rdb), updates);
  //                         } else {
  //                           // add
  //                           let obj = {
  //                             dealId: element.dealId,
  //                             dealIdHubspot: element.dealId,
  //                             portalId: element.portalId,
  //                             pipelineId: properties?.pipeline?.value || "",
  //                             properties: propsVal,
  //                             associations: element.associations,
  //                             notes:
  //                               (data.length > 0 &&
  //                               data[0][1]?.notes &&
  //                               data[0][1]?.notes.length > 0
  //                                 ? [...data[0][1]?.notes, ...notesData]
  //                                 : notesData) || [],
  //                           };
  //                           var newDealRef = push(dealsRef);
  //                           set(newDealRef, obj);
  //                         }
  //                       },
  //                       { onlyOnce: true }
  //                     );
  //                   }
  //                 });
  //             };
  //             recurEngage(0);
  //             if (result.hasMore && result.deals.length - 1 === index) {
  //               setTimeout(() => {
  //                 recur(result.offset);
  //               }, 10000);
  //             } else if (!result.hasMore && result.deals.length - 1 === index) {
  //               handleContactData();
  //             }
  //           });
  //         },
  //         (error) => {}
  //       );
  //   };
  //   return recur(0);
  // };

  // const handleContactData = () => {
  //   const recur = (vidOffset = 0) => {
  //     setLoading(true);
  //     fetch(`${GET_ALL_CONTACT}&vidOffset=${vidOffset}`, {
  //       headers: headersKey,
  //     })
  //       .then((res) => res.json())
  //       .then(
  //         (result) => {
  //           result.contacts.forEach((element, index) => {
  //             const queryData = query(
  //               contactRef,
  //               orderByChild("vid"),
  //               equalTo(element.vid)
  //             );
  //             onValue(
  //               queryData,
  //               (snapshot) => {
  //                 const val = snapshot.val() || {};
  //                 const data = Object.entries(val) || [];
  //                 if (snapshot.exists()) {
  //                   // update
  //                   let obj = {
  //                     ...data[0][1],
  //                     name: element?.properties?.firstname?.value || "",
  //                     lastname: element?.properties?.lastname?.value || "",
  //                     name_lower:
  //                       (element?.properties?.firstname?.value?.toLowerCase() ||
  //                         "") +
  //                       (element?.properties?.firstname?.value &&
  //                       element?.properties?.lastname?.value
  //                         ? " "
  //                         : "") +
  //                       (element?.properties?.lastname?.value?.toLowerCase() ||
  //                         ""),
  //                     email:
  //                       element["identity-profiles"][0]?.identities?.filter(
  //                         (d) => d.type === "EMAIL"
  //                       )[0]?.value || "",
  //                     // element["identity-profiles"][0].identities[0]?.type ===
  //                     // "EMAIL"
  //                     //   ? element["identity-profiles"][0].identities[0]?.value
  //                     //   : "",
  //                     phoneNumber: element?.properties?.phone?.value || "",
  //                     // category: "Normal",
  //                     // createdDate: element?.addedAt || "",
  //                     // vid: element.vid,
  //                     hubspotVid: element?.vid || "",
  //                     // type: "personal",
  //                   };
  //                   let updates = {};
  //                   updates[tables.contacts + data[0][0]] = obj;
  //                   update(ref(rdb), updates);
  //                 } else {
  //                   // add
  //                   let obj = {
  //                     name: element?.properties?.firstname?.value || "",
  //                     name_lower:
  //                       element?.properties?.firstname?.value?.toLowerCase() ||
  //                       "",
  //                     email:
  //                       element["identity-profiles"][0].identities.filter(
  //                         (d) => d.type === "EMAIL"
  //                       )[0]?.value || "",
  //                     //email: element["identity-profiles"][0].identities[0]?.type ===
  //                     // "EMAIL"
  //                     //   ? element["identity-profiles"][0].identities[0]?.value
  //                     //   : "",
  //                     phoneNumber: element?.properties?.phone?.value || "",
  //                     category: "Normal",
  //                     createdDate: element?.addedAt || "",
  //                     vid: element.vid || "",
  //                     hubspotVid: element.vid || "",
  //                     type: "personal",
  //                   };
  //                   var newContactRef = push(contactRef);
  //                   set(newContactRef, obj);
  //                 }
  //               },
  //               { onlyOnce: true }
  //             );
  //             if (result["has-more"] && result.contacts.length - 1 === index) {
  //               setTimeout(() => {
  //                 recur(result["vid-offset"]);
  //               }, 1000);
  //             } else if (
  //               !result["has-more"] &&
  //               result.contacts.length - 1 === index
  //             ) {
  //               setTimeout(() => {
  //                 handleCompanyData();
  //               }, 10000);
  //             }
  //           });
  //         },
  //         (error) => {}
  //       );
  //   };
  //   return recur(0);
  // };

  // const handleCompanyData = () => {
  //   const recur = (vidOffset = 0) => {
  //     setLoading(true);
  //     fetch(`${GET_ALL_COMPANY}&offset=${vidOffset}`, { headers: headersKey })
  //       .then((res) => res.json())
  //       .then(
  //         async (result) => {
  //           result.companies.forEach((element, index) => {
  //             const queryData = query(
  //               companiesRef,
  //               orderByChild("companyId"),
  //               equalTo(element.companyId)
  //             );
  //             onValue(
  //               queryData,
  //               async (snapshot) => {
  //                 // Get all contacts of company
  //                 await fetch(GET_CONTACT_OF_COMPANY(element.companyId), {
  //                   headers: headersKey,
  //                 })
  //                   .then((res) => res.json())
  //                   .then((res) => {
  //                     const val = snapshot.val() || {};
  //                     const data = Object.entries(val) || [];
  //                     let objCommon = {
  //                       name: element?.properties?.name?.value || "",
  //                       name_lower:
  //                         element?.properties?.name?.value?.toLowerCase() || "",
  //                       website: element.website || "",
  //                       phone: element?.phone || "",
  //                       description: element?.description || "",
  //                     };
  //                     if (snapshot.exists()) {
  //                       // update
  //                       let firebaseContactVids = data[0][1]?.contactVids || [],
  //                         hubspotContactVids = res?.vids || [];

  //                       let obj = {
  //                         ...data[0][1],
  //                         ...objCommon,
  //                         hubspotCompanyIds: element.companyId,
  //                         // companyId: element.companyId,
  //                         contactVids:
  //                           firebaseContactVids.concat(
  //                             hubspotContactVids.filter(
  //                               (item) => firebaseContactVids.indexOf(item) < 0
  //                             )
  //                           ) || [],
  //                       };
  //                       let updates = {};
  //                       updates[tables.companies + data[0][0]] = obj;
  //                       update(ref(rdb), updates);
  //                     } else {
  //                       // add
  //                       let obj = {
  //                         ...objCommon,
  //                         category: "Normal",
  //                         companyId: element.companyId,
  //                         type: "company",
  //                         contactVids: res?.vids || [],
  //                         hubspotCompanyIds: element.companyId,
  //                       };
  //                       var newCompaniesRef = push(companiesRef);
  //                       set(newCompaniesRef, obj);
  //                     }
  //                   });
  //               },
  //               { onlyOnce: true }
  //             );
  //             if (result["has-more"] && result.companies.length - 1 === index) {
  //               setTimeout(() => {
  //                 recur(result.offset);
  //               }, 10000);
  //             } else if (
  //               !result["has-more"] &&
  //               result.companies.length - 1 === index
  //             ) {
  //               setLoading(false);
  //             }
  //           });
  //         },
  //         (error) => {}
  //       );
  //   };
  //   return recur(0);
  // };

  // fetch(`${MAP_DATA}`)
  //   .then((res) => res.json())
  //   .then(async (result) => {
  //     var data = result.deals.filter((item) =>
  //       item.properties?.dealname?.value?.includes("Lot T")
  //     );
  //   });

  // fetch(`${GET_ALL_CONTACT}`, { headers: headersKey })
  //   .then((res) => res.json())
  //   .then(async (result) => {
  //     var constsss = result.contacts.filter((item) => item.vid === 126901);
  //   });

  return (
    <>
      <section className="vstack min-h-screen">
        <Header />
        {loading && <SyncLoader />}

        <section className="bg-darkblue py-10">
          <Container fluid={window.innerWidth > 1600 && true}
            className={window.innerWidth > 1600 ? "px-16" : ""}>
            <Row className="gy-5">
              <Col lg={6} md={4}>
                <h1 className="text-white">{getLocalText("Home.projects")}</h1>
              </Col>
              <Col lg={4} md={8} className="ms-auto">
                <Form>
                  <Form.Group className="mb-0 d-flex">
                    <Form.Control
                      type="text"
                      placeholder={getLocalText("Home.search")}
                      value={searchValue}
                      onChange={(e) => handleSearch(e)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSearchButton(e);
                        }
                      }}
                    />
                    <Button
                      variant="outline-white"
                      className="ms-2"
                      onClick={handleSearchButton}
                    >
                      {getLocalText("Home.research")}
                    </Button>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Container>
        </section>
        <div className="vstack">
          <Container fluid={window.innerWidth > 1600 && true}
            className={window.innerWidth > 1600 ? "px-16" : ""}>
            <Row className="gy-4 py-5">
              <Col xs="auto">
                <div className="hstack gap-3 flex-wrap h-full">
                  <p className="font-bold">
                    {isSearch
                      ? getLocalText("Home.searchResult")
                      : `${getLocalText("Home.allCards")}(${
                          // isSearch ? searchData.length : projectsData.length
                          projectsData.length
                        })`}
                  </p>
                  {isSearch ? (
                    <span className="badge badge-lg border rounded-pill text-pink border-light-white">
                      {searchValue}
                      <Button
                        variant="link"
                        className="bg-transparent p-0 border-0  shadow-soft-none ms-1 lh-none"
                        onClick={clearSearch}
                      >
                        <IoClose className="text-pink text-lg" />
                      </Button>
                    </span>
                  ) : null}
                </div>
              </Col>
              <Col
                xs="auto"
                className="hstack justify-content-end gap-3 flex-wrap ms-auto"
              >
                {/* <Button
                  variant="outline-green"
                  className="align-items-center"
                  onClick={hubspotToFirebaseSync}
                >
                  <GrSync className="me-2" />
                  {getLocalText("Home.syncToFirebase")}
                </Button> */}
                {/* <Button
                  variant="outline-green"
                  className="align-items-center"
                  onClick={firebaseToHubspotSync}
                >
                  <GrSync className="me-2" />
                  {getLocalText("Home.syncToHubspot")}
                </Button> */}
                {/* <Button variant="green" onClick={handleAddModalShow}>
                  {getLocalText("Home.addNewCard")}
                </Button> */}

                <AddMapModel
                  show={showAddModal}
                  handleClose={handleAddModalClose}
                  edit={editItem}
                  names={pipelineName}
                  handleNameInput={handlePipelineNameInput}
                  handleClearPipelineInput={handleClearPipelineInput}
                  handleDateTime={handleDateTimeInput}
                  timeLimit={timeLimit}
                  dateLimit={dateLimit}
                />
              </Col>
            </Row>
            <HomeTable
              // projectsData={isSearch ? searchData : projectsData}
              projectsData={projectsData}
              dealsData={dealsData}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
            <DeleteConfirmModal
              show={isDeleteConfirm}
              handleClose={handleCloseDeleteModal}
              handleConfirm={handleConfirmDelete}
            />
          </Container>
          <TablePagination
            handleNext={handleNext}
            currentPage={currentPage}
            handlePrevious={handlePrevious}
          />
        </div>
        <Footer />
      </section>
    </>
  );
}
export default Home;

import { constant } from "./constant";
export const getStageValue = (item, status) => {
  const terrainDisponible = item?.stages?.find((d) => {
    const label = d.label.toLowerCase();
    return label.includes(constant.terrain.toLowerCase());
  });

  const promiseToPurchaseToSend = item?.stages?.find((d) => {
    const label = d.label.toLowerCase();
    return (
      label.includes(constant.ENVOYER.toLowerCase()) ||
      label.includes(constant.ENVOYÉ.toLowerCase())
    );
  });

  // const promiseToPurchaseSent = item.stages.find((d) => {
  //   const label = d.label.toLowerCase();
  //   return (
  //     label.includes(constant.ENVOYÉE.toLowerCase()) ||
  //     label.includes(constant.ENVOYÉÉ.toLowerCase()) ||
  //     label.includes(constant.promiseSent.toLowerCase())
  //   );
  // });

  const promiseToPurchaseAccepted = item?.stages?.find((d) => {
    const label = d.label.toLowerCase();
    return (
      label.includes(constant.ACCEPTÉE.toLowerCase()) ||
      label.includes(constant.ACCEPTÉÉ.toLowerCase()) ||
      label.includes(constant.ACCEPTÉ.toLowerCase())
    );
  });

  var stage =
    status === constant.row1 || status === constant.row2
      ? promiseToPurchaseToSend?.stageId
      : status === constant.complete
      ? promiseToPurchaseAccepted?.stageId
      : status === ""
      ? terrainDisponible?.stageId
      : "";
  return stage;
};

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Image, Col, Row, Form, Card } from "react-bootstrap";
import { sendPasswordResetEmail } from "firebase/auth";
import { BiLeftArrowAlt } from "react-icons/bi";
import { auth } from "../services/config";
import { Logo } from "../assets/images";
import { getLocalText } from "../locales/i18nextInit";
import { emailRegex } from "../utils/validation";

function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const handleChange = (e) => {
    const { value } = e.target;
    setEmailError("");
    setEmail(value);
  };

  const validation = () => {
    var error,
      valid = true;
    if (!email.trim()) {
      error = getLocalText("ErrorMessages.emptyEmail");
      valid = false;
    } else if (!email.match(emailRegex)) {
      error = getLocalText("ErrorMessages.invalidEmail");
      valid = false;
    }
    return { error, valid };
  };

  const handleResetButton = async (e) => {
    e.preventDefault();
    var { error, valid } = await validation();
    setEmailError(error);
    if (valid) {
      sendPasswordResetEmail(auth, email)
        .then((res) => {
          setEmail("");
          navigate("/");
        })
        .catch((error) => alert(error.message));
    }
  };

  return (
    <div className="p-5 p-lg-0 min-h-lg-screen bg-black-white">
      <div className="d-flex justify-content-center">
        <Col
          lg={6}
          className="min-h-lg-screen py-lg-10 px-lg-20 pt-12 pb-8 position-relative"
        >
          <Row>
            <Col lg={10} md={12} className="mx-auto">
              <div className="text-center mb-16">
                <a href={() => false} onClick={() => navigate("/")}>
                  <Image className="header-logo shadow-b" src={Logo} />
                </a>
              </div>
              <Card className="shadow-a p-10">
                <div className="px-0 pt-0 border-bottom card-header">
                  <h3>
                    <a href={() => false} onClick={() => navigate("/")}>
                      <BiLeftArrowAlt className="me-3 text-blue" />
                    </a>
                    {getLocalText("Auth.forgotPassword")}
                  </h3>
                </div>
                <Card.Body className="px-0 pb-0">
                  <p className="text-sm mb-5">
                    {getLocalText("Auth.enterEmail")}
                  </p>
                  <Form>
                    <Form.Group className="mb-3">
                      <Form.Label className="font-bold text-base">
                        {getLocalText("Auth.email")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="email"
                        onChange={(e) => handleChange(e)}
                        value={email}
                        isInvalid={emailError}
                        isValid={email}
                      />
                      <Form.Control.Feedback type="invalid">
                        {emailError}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <div>
                      <a
                        href={() => false}
                        className="btn-green btn mt-5 text-base font-bold"
                        onClick={handleResetButton}
                      >
                        {getLocalText("Auth.resetPassword")}
                      </a>
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </div>
    </div>
  );
}
export default ForgotPassword;

import React, { useEffect, useState } from "react";
import { Col, Row, Container, Form, Button } from "react-bootstrap";
import {
  ref,
  set,
  onValue,
  query,
  limitToLast,
  limitToFirst,
  orderByKey,
  startAfter,
  endBefore,
  orderByChild,
  equalTo,
  // get,
  // child,
} from "firebase/database";
import { useLocation } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import { rdb, tables } from "../services/config";
import {
  TablePagination,
  Footer,
  CompaniesTable,
  AddCompanyModel,
  Header,
  PageLoader,
  DeleteConfirmModal,
} from "../components";
import { getLocalText } from "../locales/i18nextInit";

var limit = 20;
function Companies() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [companiesData, setCompaniesData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  // const [searchData, setSearchData] = useState([]);
  const [isSearch, setIsSearch] = useState(false);

  // add company
  const [name, setName] = useState("");
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [represent, setRepresent] = useState("");
  const [category, setCategory] = useState("DEFAULT");
  const [isShowAddModal, setIsShowAddModal] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
  const [lastVisible, setLastVisible] = useState();
  const [previousVisible, setPreviousVisible] = useState();
  const [lastItem, setLastItem] = useState();
  const [firstItem, setFirstItem] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const companiesRef = ref(rdb, tables.companies);

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      if (location.state) {
        const associatedCompanyIdsData =
          location.state.item?.associations?.associatedCompanyIds || [];
        setTotalData(associatedCompanyIdsData.length);
        if (associatedCompanyIdsData.length > 0) {
          var compnaiesDataUpdate = [];
          // for (let index = 0; index < lengthArray.length; index++) {
          associatedCompanyIdsData.forEach((element) => {
            // const element = lengthArray[index];
            const starCountRef = query(
              companiesRef,
              orderByChild("companyId"),
              equalTo(element)
            );
            onValue(
              starCountRef,
              (snapshot) => {
                const newCompanies = snapshot.val() || {};
                const companies = Object.entries(newCompanies) || [];
                compnaiesDataUpdate = [...compnaiesDataUpdate, ...companies];
                // if (associatedCompanyIdsData.length === compnaiesDataUpdate.length) {
                setCompaniesData(compnaiesDataUpdate);
                // }
                setLoading(false);
              },
              { onlyOnce: true }
            );
          });
          // }
        } else {
          setCompaniesData([]);
          setLoading(false);
        }
      } else {
        const totalCount = query(companiesRef, orderByKey());
        onValue(totalCount, (snapshot) => {
          setTotalData(Object.entries(snapshot.val())?.length);
        });

        getFirstPage();
        const starCountRefLast = query(
          companiesRef,
          orderByKey(), // orderByChild("label"),
          limitToLast(1)
        );
        setData(starCountRefLast, "last");

        const starCountRefFirst = query(
          companiesRef,
          orderByKey(),
          limitToFirst(1)
        );
        setData(starCountRefFirst, "first");
      }
    }
    fetchData();
  }, []);

  const handleNext = () => {
    if (lastItem !== companiesData[companiesData.length - 1][0]) {
      const starCountRef = query(
        companiesRef,
        orderByKey(),
        limitToFirst(limit),
        startAfter(lastVisible)
      );
      setData(starCountRef);
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (firstItem !== companiesData[0][0]) {
      const starCountRef = query(
        companiesRef,
        orderByKey(),
        limitToLast(limit),
        endBefore(previousVisible)
      );
      setData(starCountRef);
      setCurrentPage(currentPage - 1);
    }
  };

  const setData = (starCountRef, itemCheck = "") => {
    onValue(starCountRef, (snapshot) => {
      const newCompanies = snapshot.val() || {};
      const companies = Object.entries(newCompanies) || [];
      setIsSearch(false);
      setSearchValue("");
      if (itemCheck === "last") {
        setLastItem(companies[0][0]);
      } else if (itemCheck === "first") {
        setFirstItem(companies[0][0]);
      } else {
        setCompaniesData(companies);
        setLastVisible(companies[companies.length - 1][0]);
        setPreviousVisible(companies[0][0]);
        setLoading(false);
      }
    });
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    if (!e.target.value) {
      setIsSearch(false);
    }
    if (isSearch) {
      searchResultHandle(e.target.value, "val");
    }
  };

  const searchResultHandle = (val, type) => {
    // const updateCompaniesData = companiesData.filter((item) =>
    //   item[1]?.name?.toUpperCase().includes(searchValue?.toUpperCase())
    // );
    // setSearchData(updateCompaniesData);
    var searchKey = type ? val : searchValue;
    if (searchKey) {
      const starCountRef = query(
        companiesRef,
        orderByChild("name_lower"),
        equalTo(searchKey.toLowerCase())
      );
      setValue(starCountRef);
    } else {
      getFirstPage();
    }
  };

  const setValue = (starCountRef) => {
    onValue(
      starCountRef,
      (snapshot) => {
        const newCompanies = snapshot.val() || {};
        const companies = Object.entries(newCompanies) || [];
        setCompaniesData(companies);
        setLoading(false);
      },
      { onlyOnce: true }
    );
  };

  const getFirstPage = () => {
    const starCountRef = query(companiesRef, orderByKey(), limitToFirst(limit));
    setData(starCountRef);
  };

  const handleSearchButton = (e) => {
    e.preventDefault();
    searchResultHandle();
    setIsSearch(searchValue ? true : false);
  };

  const clearSearch = () => {
    setIsSearch(false);
    // setSearchData([]);
    setSearchValue("");
    getFirstPage();
  };

  const handleModalInput = (e, type) => {
    type(e.target.value);
  };

  const closeAddModal = () => {
    setEditItem(null);
    setIsShowAddModal(false);
    handleClearInputs();
  };

  const handleClearInputs = () => {
    setEmail("");
    setName("");
    setTelephone("");
    setCategory("DEFAULT");
  };

  const handleEdit = (item, index, key) => {
    const { email, name, phone, category } = item[1];
    setEditItem({ item, index, key });
    setEmail(email);
    setTelephone(phone);
    setName(name);
    setCategory(category);
    showAddModal(true);
  };

  const handleUpdateStatus = (index, categoryValue) => {
    var newCompaniesData = companiesData.map((d, i) => {
      if (i === index) {
        return [d[0], { ...d[1], category: categoryValue }];
      }
      return d;
    });
    setCompaniesData(newCompaniesData);
  };

  const handleAddUpdateData = (obj, type = "add") => {
    var newCompaniesData = [];
    if (type === "update") {
      newCompaniesData = companiesData.map((item) => {
        return item[0] === obj[0] ? obj : item;
      });
    } else {
      newCompaniesData = [...companiesData, obj];
    }
    setCompaniesData(newCompaniesData);
  };

  const showAddModal = () => setIsShowAddModal(true);

  const handleDelete = (item, index, key) => {
    setIsDeleteConfirm(true);
    setEditItem({ item, index, key });
  };

  const handleConfirmDelete = () => {
    setIsDeleteConfirm(false);
    set(ref(rdb, `${tables.companies}${editItem.item[0]}`), null);
    setEditItem(null);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteConfirm(false);
    setEditItem(null);
  };

  return (
    <>
      <section className="vstack min-h-screen">
        <Header />
        {loading && <PageLoader />}
        <section className="bg-darkblue py-10">
          <Container fluid={window.innerWidth > 1600 && true}
            className={window.innerWidth > 1600 ? "px-16 position-static" : "position-static"}>
            <Row className="gy-5">
              <Col lg={6} md={4}>
                <h1 className="text-white">
                  {getLocalText("Home.companies")}{" "}
                </h1>
                {location?.state?.item?.properties?.dealname?.value || ""}
              </Col>
              <Col lg={4} md={8} className="ms-auto">
                <Form>
                  <Form.Group className="mb-0 d-flex">
                    <Form.Control
                      type="text"
                      placeholder={getLocalText("Home.search")}
                      value={searchValue}
                      onChange={(e) => handleSearch(e)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSearchButton(e);
                        }
                      }}
                    />
                    <Button
                      variant="outline-white"
                      className="ms-2"
                      onClick={handleSearchButton}
                    >
                      {getLocalText("Home.search")}
                    </Button>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Container>
        </section>
        <div className="vstack">
          <Container fluid={window.innerWidth > 1600 && true}
            className={window.innerWidth > 1600 ? "px-16" : ""}>
            <Row className="my-5">
              <Col md={6}>
                <div className="hstack gap-3 flex-wrap">
                  <p className="font-bold">
                    {isSearch
                      ? getLocalText("Home.searchResult")
                      : `${getLocalText("Users.allCompanies")}(${
                          // isSearch ? searchData.length : companiesData.length
                          totalData
                        })`}
                  </p>
                  {isSearch ? (
                    <span className="badge badge-lg border rounded-pill text-pink border-light-white">
                      {searchValue}
                      <Button
                        variant="link"
                        className="bg-transparent p-0 border-0  shadow-soft-none ms-1 lh-none"
                        onClick={clearSearch}
                      >
                        <IoClose className="text-pink text-lg" />
                      </Button>
                    </span>
                  ) : null}
                </div>
              </Col>
              <Col md={6} className="text-end">
                {/* temporary hide */}
                <Button variant="green" onClick={showAddModal}>
                  {getLocalText("Users.addNewCompany")}
                </Button>
                <AddCompanyModel
                  location={location}
                  name={name}
                  telephone={telephone}
                  email={email}
                  address={address}
                  represent={represent}
                  // category={category}
                  setName={setName}
                  setEmail={setEmail}
                  setTelephone={setTelephone}
                  setAddress={setAddress}
                  setRepresent={setRepresent}
                  // setCategory={setCategory}
                  handleModalInput={handleModalInput}
                  show={isShowAddModal}
                  handleClose={closeAddModal}
                  handleClearInputs={handleClearInputs}
                  edit={editItem}
                  handleAddUpdateData={handleAddUpdateData}
                />
              </Col>
            </Row>
            <CompaniesTable
              // companiesData={isSearch ? searchData : companiesData}
              companiesData={companiesData}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              handleUpdateStatus={handleUpdateStatus}
            />
            <DeleteConfirmModal
              show={isDeleteConfirm}
              handleClose={handleCloseDeleteModal}
              handleConfirm={handleConfirmDelete}
            />
          </Container>
          <TablePagination
            handleNext={handleNext}
            currentPage={currentPage}
            handlePrevious={handlePrevious}
          />
        </div>
        <Footer />
      </section>
    </>
  );
}
export default Companies;

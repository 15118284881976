import React from "react";
import { Routes, Route } from "react-router-dom";
import {
  Login,
  ForgotPassword,
  Home,
  Users,
  Phase,
  Information,
  Associations,
  Companies,
} from "../pages";
import PrivateRoute from "./privateRouter";
const isLoggedIn = localStorage.getItem("isLogin") ? true : false;

const MainRouter = () => (
  <Routes>
    <Route exact path="/" element={!isLoggedIn ? <Login /> : <Home />} />
    <Route path="/forgot-password" element={<ForgotPassword />} />
    <Route
      path="/home"
      element={
        <PrivateRoute>
          <Home />
        </PrivateRoute>
      }
    />
    <Route
      path="/phase"
      element={
        <PrivateRoute>
          <Phase />
        </PrivateRoute>
      }
    />
    <Route
      path="/users"
      element={
        <PrivateRoute>
          <Users />
        </PrivateRoute>
      }
    />
    <Route
      path="/companies"
      element={
        <PrivateRoute>
          <Companies />
        </PrivateRoute>
      }
    />
    <Route
      path="/associations"
      element={
        <PrivateRoute>
          <Associations />
        </PrivateRoute>
      }
    />
    <Route
      path="/information"
      element={
        <PrivateRoute>
          <Information />
        </PrivateRoute>
      }
    />
  </Routes>
);

export default MainRouter;

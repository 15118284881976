// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link {
  color: #152536;
}
.link:hover {
  color: #152536;
}

.header-navbar {
  position: sticky;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}
.header-navbar .navigation-menu .navbar-nav .nav-link {
  color: #6c757d;
}
.header-navbar .navigation-menu .navbar-nav .nav-link:hover {
  color: #152536;
}
.header-navbar .navigation-menu .navbar-nav .nav-link.active {
  color: #152536;
}

.recent-activities-active svg {
  fill: #6C757D;
}
.recent-activities-active .active svg {
  fill: #e58888;
}

@media (max-width: 991.98px) {
  .header-navbar .navigation-menu {
    position: absolute;
    width: 100%;
    top: calc(100% + 0px);
    background-color: #fff;
    left: 0;
    box-shadow: 0px 70px 54px rgba(0, 0, 0, 0.1);
  }
  .header-navbar .navigation-menu::before {
    margin: 1rem 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/header/style.scss","webpack://./src/assets/scss/variable.scss"],"names":[],"mappings":"AAGA;EACI,cCJG;ADEP;AAGI;EACI,cCND;ADKP;;AAIA;EACI,gBAAA;EACA,QAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,uCAAA;AADJ;AAIY;EACI,cAAA;AAFhB;AAGgB;EACI,cCrBb;ADoBP;AAGgB;EACI,cCxBb;ADuBP;;AASI;EACI,aCrBG;ADeX;AASQ;EACI,aC/BN;ADwBN;;AAaA;EAEQ;IACI,kBAAA;IACA,WAAA;IACA,qBAAA;IACA,sBC7CL;ID8CK,OAAA;IACA,4CAAA;EAXV;EAYU;IACI,cAAA;EAVd;AACF","sourcesContent":["@import \"../../assets/scss/variable.scss\";\n\n\n.link {\n    color: $blue;\n    &:hover {\n        color: $blue;\n    }\n}\n.header-navbar {\n    position: sticky;\n    top: 0px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    box-shadow: 0 0 30px rgba(0, 0, 0, 0.20);\n    .navigation-menu  {\n        .navbar-nav {\n            .nav-link{\n                color: #6c757d;\n                &:hover {\n                    color: $blue;\n                }\n                &.active {\n                    color: $blue;\n                }\n            }\n        }\n    }\n}\n\n.recent-activities-active {\n    svg{\n        fill: $stormGrey;\n    }\n    .active {\n        svg{\n            fill: $pink;\n        }\n    }\n}\n\n\n@media(max-width: 991.98px) {\n    .header-navbar {\n        .navigation-menu {\n            position: absolute;\n            width: 100%;\n            top: calc(100% + 0px);\n            background-color: $white;\n            left: 0;\n            box-shadow:0px 70px 54px rgb(0 0 0 / 10%);\n            &::before{\n                margin: 1rem 0;\n            }\n        }\n    }\n}","$blue: #152536;\n$green: #8ea385;\n$darkblue: #20232c;\n$light-white:#DEE2E6;\n$white:#fff;\n$gray:#808080;\n$pink:#e58888;\n$bright-blue:#006BFF;\n$ceramic:#F8F9FA;\n$green-grey:#7C8D75;\n$lightGrey:#D6D6D6;\n$pastelGrey:#CFCFCF;\n$stormGrey:#6C757D;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

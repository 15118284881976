import React from "react";
import { Table } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import { getLocalText } from "../locales/i18nextInit";

function AssociationTable(props) {
  const { associationData } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const headerData = ["#", getLocalText("Home.name"), ""];

  return (
    <>
      <Table responsive className="mb-5 data-table">
        <thead className="border-top border-light-white">
          <tr>
            {headerData.map((item, index) => {
              return (
                <th
                  key={index.toString()}
                  className="text-base text-blue text-capitalize"
                >
                  {item}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {associationData.map((item, index) => {
            return (
              <tr key={index.toString()}>
                <td className="font-bold text-base text-blue">{index + 1}</td>
                <td className="text-base text-blue">{item?.name}</td>
                <td className="text-base text-end">
                  <a
                    href={() => false}
                    className="pe-3 pe-md-2 pe-lg-0 text-blue cursor-pointer"
                    onClick={() =>
                      navigate(index === 0 ? "/users" : "/companies", {
                        state: location.state,
                      })
                    }
                  >
                    <BsArrowRight />
                  </a>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}
export default AssociationTable;

export const constant = {
  row1: "1er rang",
  row2: "2ème rang",
  complete: "Complet",
  nonDisponible: "Non disponible",
  disponible:"Disponible",
  terrainDisponible: "TERRAIN DISPONIBLE",
  terrain: "DISPONIBLE",
  promiseToPurchaseToSend: "PROMESSE D'ACHAT À ENVOYER",
  ENVOYER: "À ENVOYER",
  ENVOYÉ: "À ENVOYÉ",
  promiseToPurchaseSent: "PROMESSE D'ACHAT ENVOYÉE",
  ENVOYÉE: "D'ACHAT ENVOYÉE",
  ENVOYÉÉ: "D'ACHAT ENVOYÉÉ",
  promiseSent: "Promesse d'achat ENVOYER",
  promiseToPurchaseAccepted: "Promesse d'achat ACCEPTÉE",
  ACCEPTÉE: "ACCEPTÉE",
  ACCEPTÉÉ: "ACCEPTÉÉ",
  ACCEPTÉ: "ACCEPTÉ",
};

import React, { useState } from "react";
import { Image, Container, Navbar, Nav, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../../services/config";
import { getLocalText } from "../../locales/i18nextInit";
import { HomeLogo } from "../../assets/images";
import "./style.scss";
import RecentActivities from "../recentActivities/recentActivities";
import { BsListUl } from "react-icons/bs";

function Header() {
  const [clicked, setClicked] = useState("");
  const navigate = useNavigate();
  const loginUserData = localStorage.getItem("@loginUserData");

  const logout = () => {
    localStorage.clear();
    signOut(auth);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => {
    clicked ? setClicked("") : setClicked("");
    setShow(false);
  };
  const handleShow = () => {
    clicked ? setClicked("") : setClicked("active");
    setShow(true);
  };
  const RecentActivitiesSetting = [
    {
      backdropClassName: "opacity-0",
    },
  ];
  return (
    <>
      <Navbar expand="lg" className="px-0 bg-ceramic header-navbar">
        <Container fluid={window.innerWidth > 1600 && true}
          className={window.innerWidth > 1600 ? "px-16 position-static" : "position-static"}>
          <Navbar.Brand>
            <Image src={HomeLogo} />
          </Navbar.Brand>
          <div
            className="d-block d-lg-none ms-auto recent-activities-active"
            key="1"
          >
            <Button
              key="0"
              variant="transparent"
              onClick={handleShow}
              className={
                clicked +
                " recent-activities-button shadow-none border-end rounded-0 border-top-0 border-start-0 border-bottom-0 border-pastel-grey py-0"
              }
            >
              <BsListUl className="text-xl" />
            </Button>
          </div>
          <Navbar.Toggle
            aria-controls="nav-menu"
            className="ms-5 border-0"
            key="0"
          />
          <Navbar.Collapse
            id="nav-menu"
            className="navigation-menu pb-4 pb-lg-0"
          >
            <div className="menu-list d-block d-lg-flex w-full">
              <Nav className="me-auto navbar-menu align-items-lg-center gap-lg-10 ms-lg-10">
                <Nav.Link
                  onClick={() => navigate("/home")}
                  key="0"
                  className="bg-transparent py-4 p-lg-0"
                >
                  {getLocalText("Home.projects")}
                </Nav.Link>
                <Nav.Link
                  onClick={() => navigate("/users")}
                  key="1"
                  className="bg-transparent py-4 p-lg-0"
                >
                  {getLocalText("Home.users")}
                </Nav.Link>
                <Nav.Link
                  onClick={() => navigate("/companies")}
                  key="2"
                  className="bg-transparent py-4 p-lg-0"
                >
                  {getLocalText("Home.companies")}
                </Nav.Link>
              </Nav>
              <div
                className="d-none d-lg-flex recent-activities-active"
                key="0"
              >
                <Button
                  variant="transparent"
                  onClick={handleShow}
                  className={
                    clicked +
                    " recent-activities-button shadow-none border-end rounded-0 border-top-0 border-start-0 border-bottom-0 border-pastel-grey pe-8 me-lg-4 py-0"
                  }
                >
                  <BsListUl className="text-xl" />
                </Button>
              </div>
              <Nav className="gap-lg-10">
                <Nav.Link
                  className="active bg-transparent p-lg-0"
                  onClick={() => navigate("/information")}
                  key="0"
                >
                  {getLocalText("Auth.hello")}{" "}
                  {JSON.parse(loginUserData)?.name} !
                </Nav.Link>
                <Nav.Link
                  href="/"
                  onClick={logout}
                  className="p-lg-0 bg-transparent"
                  key="1"
                >
                  {getLocalText("Auth.logout")}
                </Nav.Link>
              </Nav>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {RecentActivitiesSetting.map((props) => (
        <RecentActivities
          show={show}
          onHide={handleClose}
          placement={"end"}
          {...props}
          key="0"
        />
      ))}
    </>
  );
}
export default Header;

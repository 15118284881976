import React from "react";
import { useLocation } from "react-router";
import { Navigate } from "react-router-dom";

const PrivateRoute = (props) => {
  const { children } = props;
  const isLoggedIn = localStorage.getItem("isLogin") ? true : false;
  const location = useLocation();
  return isLoggedIn ? (
    <>{children}</>
  ) : (
    <Navigate
      replace={true}
      to="/"
      state={{ from: `${location.pathname}${location.search}` }}
    />
  );
};

export default PrivateRoute;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner-filter {
  position: fixed;
  z-index: 999;
  height: 100vh;
  width: 100vw;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.44);
}

.spin-loader {
  border: 16px solid #ffffff;
  border-top: 16px solid rgb(142, 163, 133);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  position: fixed;
  top: calc(50% - 60px);
  left: calc(50% - 60px);
  z-index: 5001;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/spinner/style.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,YAAA;EACA,aAAA;EACA,YAAA;EACA,QAAA;EACA,qCAAA;AACJ;;AAEA;EACI,0BAAA;EACA,yCAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,eAAA;EACA,qBAAA;EACA,sBAAA;EACA,aAAA;EACA,kCAAA;AACJ;;AAEA;EACI;IAAK,uBAAA;EAEP;EADE;IAAO,yBAAA;EAIT;AACF","sourcesContent":[".spinner-filter {\n    position: fixed;\n    z-index: 999;\n    height: 100vh;\n    width: 100vw;\n    inset: 0;\n    background-color: rgba(0, 0, 0, 0.44);\n}\n\n.spin-loader {\n    border: 16px solid #ffffff;\n    border-top: 16px solid rgb(142, 163, 133);\n    border-radius: 50%;\n    width: 120px;\n    height: 120px;\n    position: fixed;\n    top: calc(50% - 60px);\n    left: calc(50% - 60px);\n    z-index: 5001;\n    animation: spin 2s linear infinite;\n}\n\n@keyframes spin {\n    0% { transform: rotate(0deg); }\n    100% { transform: rotate(360deg); }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import { InformationFrom, Footer, Header } from "../components";
import { getLocalText } from "../locales/i18nextInit";

function Information() {
  return (
    <>
      <section className="vstack min-h-screen">
        <Header />
        <section className="bg-darkblue py-10">
          <Container fluid={window.innerWidth > 1600 && true}
            className={window.innerWidth > 1600 ? "px-16 position-static" : "position-static"}>
            <Row className="gy-5">
              <Col lg={12} md={12}>
                <h1 className="text-white">
                  {getLocalText("Informations.yourProfile")}
                </h1>
              </Col>
            </Row>
          </Container>
        </section>
        <div className="flex-fill">
          <Container fluid={window.innerWidth > 1600 && true}
            className={window.innerWidth > 1600 ? "px-16 position-static" : "position-static"}>
            <InformationFrom />
          </Container>
        </div>
        <Footer />
      </section>
    </>
  );
}
export default Information;

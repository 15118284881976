import Login from "./login";
import ForgotPassword from "./forgot-password";
import Home from "./home";
import Phase from "./phase";
import Users from "./users";
import Information from "./information";
import Associations from "./associations";
import Companies from "./companies";

export {
  Login,
  ForgotPassword,
  Home,
  Phase,
  Users,
  Information,
  Associations,
  Companies,
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  z-index: 999;
}`, "",{"version":3,"sources":["webpack://./src/components/loader/style.scss"],"names":[],"mappings":"AAEA;EACI,WAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,8BAAA;EACA,kCAAA;UAAA,0BAAA;EACA,YAAA;AADJ","sourcesContent":["@import \"../../assets/scss/variable.scss\";\n\n.loader-container {\n    width: 100%;\n    height: 100vh;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: fixed;\n    background: rgba(0, 0, 0, 0.3);\n    backdrop-filter: blur(3px);\n    z-index: 999;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

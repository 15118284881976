import React from "react";
import { Table, Form } from "react-bootstrap";
import { FiEdit } from "react-icons/fi";
import { VscTrash } from "react-icons/vsc";
import moment from "moment";
import { getLocalText } from "../locales/i18nextInit";

function PhaseFilter(props) {
  const headerData = [
    getLocalText("Home.name"),
    getLocalText("Home.lot"),
    getLocalText("Home.createdDate"),
    getLocalText("Home.status"),
  ];
  return (
    <>
      <Table responsive className="mb-5 border mt-5 data-table">
        <thead className="border-top border-light-white">
          <tr>
            {headerData.map((item, index) => (
              <th
                key={index.toString()}
                className="text-base text-blue text-capitalize"
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-base text-blue">
              {props?.projectDetails?.label}
            </td>
            <td className="text-base text-blue">{props.dealsDataLength}</td>
            <td className="text-base text-blue">
              {moment(props?.projectDetails?.createdAt).format("DD MMM YYYY HH:MM")}
            </td>
            <td className="text-blue">
              <Form.Select
                aria-label="Default select example"
                className="px-2 text-base select-placeholder w-56"
                // defaultValue={"DEFAULT"}
                value={props?.projectDetails?.status}
                disabled
              >
                <option disabled value="DEFAULT">
                  {getLocalText("Phase.chooseHere")}
                </option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </Form.Select>
            </td>
            {/* <td className="text-base text-end">
              <a href="#" className="text-blue">
                <FiEdit />
              </a>
              <a href="#">
                <VscTrash className="mx-lg-5 mx-md-5 mx-2 text-pink" />
              </a>
            </td> */}
          </tr>
        </tbody>
      </Table>
    </>
  );
}
export default PhaseFilter;

import React, { useEffect, useState } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import { BsFileEarmarkMinus } from "react-icons/bs";
import { ref, update, onValue } from "firebase/database";
import { updateEmail, updatePassword } from "firebase/auth";
import { getLocalText } from "../../locales/i18nextInit";
import { rdb, auth, tables } from "../../services/config";

function InformationFrom() {
  const loginUserData = localStorage.getItem("@loginUserData");
  const parsingUserData = JSON.parse(loginUserData);
  const [name, setName] = useState(parsingUserData?.name);
  const [email, setEmail] = useState(parsingUserData?.email);
  const [phoneNumber, setPhoneNumber] = useState(parsingUserData?.phoneNumber);
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  useEffect(() => {
    var userUID = localStorage.getItem("@userUID");
    const starCountRef = ref(rdb, `${tables.users}${userUID}`);
    onValue(starCountRef, (snapshot) => {
      const usersData = snapshot.val() || {};
      localStorage.setItem("@loginUserData", JSON.stringify(usersData));
      setName(usersData.name);
      setEmail(usersData.email);
      setPhoneNumber(usersData.phoneNumber);
    });
  }, []);

  const updateInfo = () => {
    var userUID = localStorage.getItem("@userUID");
    var updates = {};
    var obj = {
      email: email,
      phoneNumber: phoneNumber,
      name: name,
      type: "admin",
    };
    updates[tables.users + userUID] = obj;
    update(ref(rdb), updates);
    updateEmail(auth.currentUser, email)
      .then((res) => {})
      .catch((error) => {});
  };

  const editPassword = () => {
    var user = auth.currentUser;
    if (newPassword === confirmPassword) {
      updatePassword(user, newPassword)
        .then(() => {
          console.log("Password updated!");
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      alert("Password are not match");
    }
  };

  const cancelButton = () => {
    return (
      <Button variant="outline-green">{getLocalText("Home.cancel")}</Button>
    );
  };
  const registerButton = (type = "password") => {
    return (
      <Button
        onClick={() => {
          if (type === "info") {
            updateInfo();
          } else {
            editPassword();
          }
        }}
        variant="green"
        className="me-3"
      >
        {getLocalText("Informations.register")}
      </Button>
    );
  };

  const handleInput = (e, type) => {
    type(e.target.value);
    clearErrors();
  };

  const clearErrors = () => {
    setNameError("");
    setEmailError("");
    setPhoneNumberError("");
    setNewPasswordError("");
    setConfirmPasswordError("");
  };

  const renderInfoInput = (
    itemName,
    value,
    placeholder,
    title,
    error,
    setType
  ) => {
    return (
      <Col md={4}>
        <Form.Group className="mb-3">
          <Form.Label className="font-bold text-base">{title}</Form.Label>
          <Form.Control
            type="text"
            name={itemName}
            placeholder={placeholder}
            value={value}
            onChange={(e) => handleInput(e, setType)}
          />
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </Form.Group>
      </Col>
    );
  };

  const renderPassword = (itemName, value, title, error, setType) => {
    return (
      <Col md={4}>
        <Form.Group className="mb-3">
          <Form.Label className="font-bold text-base">{title}</Form.Label>
          <Form.Control
            name={itemName}
            value={value}
            onChange={(e) => handleInput(e, setType)}
            type="password"
            placeholder="**********"
            isInvalid={error}
            isValid={itemName}
          />
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </Form.Group>
      </Col>
    );
  };

  return (
    <>
      <Row className="gy-5 my-5 align-items-center">
        <Col md={12}>
          <h4>{getLocalText("Informations.yourInformations")}</h4>
        </Col>
        <Col sm={6}>
          <p className="mb-0">{getLocalText("Informations.personalInfo")}</p>
        </Col>
        <Col sm={6} className="text-md-end">
          {registerButton("info")}
          {cancelButton()}
        </Col>
        {renderInfoInput(
          "name",
          name,
          "John Smith",
          getLocalText("Home.name"),
          nameError,
          setName
        )}
        {renderInfoInput(
          "email",
          email,
          "user@email.com",
          getLocalText("Auth.email"),
          emailError,
          setEmail
        )}
        {renderInfoInput(
          "phoneNumber",
          phoneNumber,
          "+1 (234) 567-8910",
          getLocalText("Users.phoneNumber"),
          phoneNumberError,
          setPhoneNumber
        )}
        <hr></hr>

        <Col sm={6}>
          <p className="mb-0">{getLocalText("Informations.security")}</p>
        </Col>
        <Col sm={6} className="text-md-end text-start ">
          {registerButton()}
          {cancelButton()}
        </Col>
        {/* {renderPassword(getLocalText("Informations.currentPassword"))} */}
        {renderPassword(
          "newPassword",
          newPassword,
          getLocalText("Informations.newPassword"),
          newPasswordError,
          setNewPassword
        )}
        {renderPassword(
          "confirmPassword",
          confirmPassword,
          getLocalText("Informations.confirmPassword"),
          confirmPasswordError,
          setConfirmPassword
        )}
      </Row>
      {/* <div>
        <a className="text-bright-blue d-block my-5">
          <BsFileEarmarkMinus />
          <span className="border-bright-blue-bottom">
            {getLocalText("Informations.terms")}
          </span>
        </a>
        <a className="text-bright-blue d-block my-5">
          <BsFileEarmarkMinus className="bright-blue" />
          <span className="border-bright-blue-bottom">
            {getLocalText("Informations.privacy")}
          </span>
        </a>
      </div> */}
    </>
  );
}
export default InformationFrom;

import React from "react";
import { Button, Offcanvas } from "react-bootstrap";
import { IoIosClose } from "react-icons/io";
import { getLocalText } from "../../locales/i18nextInit";
import "./style.scss";

function RecentActivities({ ...props }) {
  return (
    <>
      <Offcanvas
        show={props.show}
        {...props}
        className="recent-activities-offcanvas"
      >
        <Offcanvas.Header className="nav-tabs" key={1}>
          <Offcanvas.Title className="font-regular text-base text-blue">
            {getLocalText("Informations.recentActivity")}
          </Offcanvas.Title>
          <Button
            variant="transparent text-black border-0 shadow-none"
            onClick={props.onHide}
          >
            <IoIosClose className="text-xl" />
          </Button>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0 recent-activities">
          <div className="nav-tabs" key={0}>
            <div className="p-6 recent-activities-content">
              <h6 className="text-light-grey text-uppercase text-xs font-bold pb-5">
                TODAY
              </h6>
              <div className="pb-5" key={0}>
                <p className="font-regular text-base text-blue lh-none">
                  John has synced the dashboard with firebase.
                </p>
                <span className="text-pink text-xs">16:22h</span>
              </div>
              <div className="pb-5" key={1}>
                <p className="font-regular text-base text-blue lh-none">
                  Maurice has moved lot F-1 to “Closed”.
                </p>
                <span className="text-pink text-xs">15:54h</span>
              </div>
              <div className="pb-5" key={2}>
                <p className="font-regular text-base text-blue lh-none">
                  Anna has synced the dashboard with firebase.
                </p>
                <span className="text-pink text-xs">14:13h</span>
              </div>
              <div>
                <p className="font-regular text-base text-blue lh-none">
                  Maurice has moved lot F-3 to “Closed”.
                </p>
                <span className="text-pink text-xs">09:13h</span>
              </div>
            </div>
          </div>
          <div className="nav-tabs">
            <div className="p-6">
              <h6 className="text-light-grey text-uppercase text-xs font-bold pb-5">
                YESTERDAY
              </h6>
              <div>
                <p className="font-regular text-base text-blue lh-none">
                  John has synced the dashboard with firebase.
                </p>
                <span className="text-pink text-xs">16:22h</span>
              </div>
            </div>
          </div>
          <div className="nav-tabs">
            <div className="p-6">
              <h6 className="text-light-grey text-uppercase text-xs font-bold pb-5">
                APR 13th
              </h6>
              <div>
                <p className="font-regular text-base text-blue lh-none">
                  Anna has synced the dashboard with firebase.
                </p>
                <span className="text-pink text-xs">16:22h</span>
              </div>
            </div>
          </div>
          <div className="nav-tabs">
            <div className="p-6">
              <h6 className="text-light-grey text-uppercase text-xs font-bold pb-5">
                APR 9th
              </h6>
              <div className="pb-5">
                <p className="font-regular text-base text-blue lh-none">
                  Anna has synced the dashboard with firebase.
                </p>
                <span className="text-pink text-xs">16:22h</span>
              </div>
              <div className="pb-5">
                <p className="font-regular text-base text-blue lh-none">
                  John has synced the dashboard with firebase.
                </p>
                <span className="text-pink text-xs">16:22h</span>
              </div>
              <div className="pb-5">
                <p className="font-regular text-base text-blue lh-none">
                  Anna has synced the dashboard with firebase.
                </p>
                <span className="text-pink text-xs">16:22h</span>
              </div>
              <div>
                <p className="font-regular text-base text-blue lh-none">
                  John has synced the dashboard with firebase.
                </p>
                <span className="text-pink text-xs">16:22h</span>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default RecentActivities;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recent-activities-offcanvas.offcanvas {
  box-shadow: -10px 0px 54px rgba(0, 0, 0, 0.1);
  height: calc(100vh - 88px);
  top: 88px;
}
.recent-activities-offcanvas .recent-activities {
  scrollbar-width: 2px;
}
.recent-activities-offcanvas .recent-activities::-webkit-scrollbar {
  width: 2px;
}
.recent-activities-offcanvas .recent-activities::-webkit-scrollbar-thumb {
  background: #888;
}
.recent-activities-offcanvas .recent-activities::-webkit-scrollbar-track {
  background: #f1f1f1;
}

@media screen and (max-width: 991.98px) {
  .recent-activities-offcanvas.offcanvas {
    height: calc(100vh - 76px);
    top: 76px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/recentActivities/style.scss"],"names":[],"mappings":"AACI;EACI,6CAAA;EACA,0BAAA;EACA,SAAA;AAAR;AAEI;EACI,oBAAA;AAAR;AACQ;EACI,UAAA;AACZ;AACQ;EACI,gBAAA;AACZ;AACQ;EACI,mBAAA;AACZ;;AAIA;EAEQ;IACI,0BAAA;IACA,SAAA;EAFV;AACF","sourcesContent":[".recent-activities-offcanvas{\n    &.offcanvas{\n        box-shadow: -10px 0px 54px rgba(0 0 0 /10%);\n        height: calc(100vh - 88px);\n        top: 88px;\n    }\n    .recent-activities{\n        scrollbar-width: 2px;\n        &::-webkit-scrollbar{\n            width: 2px;\n        }\n        &::-webkit-scrollbar-thumb {\n            background: #888;\n        }\n        &::-webkit-scrollbar-track {\n            background: #f1f1f1;\n        }\n    }\n}\n\n@media screen and (max-width: 991.98px){\n    .recent-activities-offcanvas{\n        &.offcanvas{\n            height: calc(100vh - 76px);\n            top: 76px\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

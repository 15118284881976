import React, { useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { ref, set, update, push } from "firebase/database";
import { getLocalText } from "../locales/i18nextInit";
import { rdb, tables } from "../services/config";

function AddCompanyModel(props) {
  const [nameError, setNameError] = useState("");
  // const [emailError, setEmailError] = useState("");
  const [telephoneError, setTelephoneError] = useState("");
  // const [categoryError, setCategoryError] = useState("");

  const {
    name,
    setName,
    // email,
    // setEmail,
    telephone,
    setTelephone,
    // address,
    // setAddress,
    // represent,
    // setRepresent,
    // category,
    // setCategory,
    handleModalInput,
    handleClose,
    show,
    handleClearInputs,
    edit,
    location,
    handleAddUpdateData,
  } = props;

  const handleInput = (e, type) => {
    type(e.target.value);
    handleModalInput(e, type);
    clearErrors();
  };

  const clearErrors = () => {
    setNameError("");
    // setEmailError("");
    setTelephoneError("");
    // setCategoryError("");
  };

  const renderInput = (itemName, value, title, error, setType) => {
    return (
      <Col lg={6}>
        <Form.Group className="mb-3">
          <Form.Label className="font-bold text-base">{title}</Form.Label>
          <Form.Control
            type="text"
            name={itemName}
            value={value}
            onChange={(e) => handleInput(e, setType)}
            isInvalid={error}
            isValid={itemName}
          />
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </Form.Group>
      </Col>
    );
  };

  const handleAddEditCompany = () => {
    // return null;
    if (!name.trim()) {
      setNameError("Enter name");
    }
    // else if (!email.trim()) {
    //   setEmailError("Enter email");
    // }
    else if (!telephone.trim()) {
      setTelephoneError("Enter telephone");
    }
    // else if (category === "DEFAULT") {
    //   setCategoryError("Select category");
    // }
    else {
      var companyId = new Date().getTime();
      var companyObj = {
        name: name || "",
        name_lower: name.toLowerCase() || "",
        website: edit ? edit?.item[1].website : "",
        phone: telephone || "",
        description: edit ? edit?.item[1].description : "",
        category: edit ? edit?.item[1].category : "Normal",
        companyId: edit ? edit?.item[1].companyId : companyId,
        type: "company",
        contactVids: edit ? edit?.item[1].contactVids || [] : [],
      };

      if (edit) {
        companyObj = { ...edit?.item[1], ...companyObj };
        let updates = {};
        updates[tables.companies + edit.item[0]] = companyObj;
        update(ref(rdb), updates);
        handleAddUpdateData([edit?.item[0], companyObj], "update");
      } else {
        const comapniesGroups = ref(rdb, tables.companies);
        var newPostRef = push(comapniesGroups);
        set(newPostRef, companyObj);
        handleAddUpdateData([newPostRef.key, companyObj]);
        const locationStateData = location?.state?.item;
        if (locationStateData) {
          let objDeal = {
            ...locationStateData,
            associations: {
              associatedCompanyIds: locationStateData?.associations
                ?.associatedCompanyIds
                ? [
                    ...locationStateData?.associations?.associatedCompanyIds,
                    companyId,
                  ]
                : [companyId],
              associatedVids:
                locationStateData?.associations?.associatedVids || [],
              // associatedDealIds:
              //   locationStateData?.associations?.associatedDealIds,
              // associatedTicketIds:
              //   locationStateData?.associations?.associatedTicketIds,
            },
          };
          let updates = {};
          updates[tables.deals + location?.state?.key] = objDeal;
          update(ref(rdb), updates);
        }
      }
      handleClearInputs();
      clearErrors();
      handleClose();
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-xl">
            {edit
              ? getLocalText("Users.editNewCompany")
              : getLocalText("Users.addNewCompany")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              {renderInput(
                "name",
                name,
                getLocalText("Home.name"),
                nameError,
                setName
              )}

              {renderInput(
                "telephone",
                telephone,
                getLocalText("Users.phoneNumber"),
                telephoneError,
                setTelephone
              )}
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className="justify-content-start">
          <Button variant="green" onClick={handleAddEditCompany}>
            {edit ? getLocalText("Home.edit") : getLocalText("Home.add")}
          </Button>
          <Button
            variant="outline-green"
            onClick={() => {
              handleClose();
              handleClearInputs();
              clearErrors();
            }}
          >
            {getLocalText("Home.cancel")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddCompanyModel;

import React, { useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { ref, set, update, push } from "firebase/database";
import { getLocalText } from "../locales/i18nextInit";
import { rdb, tables } from "../services/config";
import { constant } from "../utils/constant";
import { getStageValue } from "../utils/helper";

function AddNewLot(props) {
  const {
    pipelineItem,
    dealName,
    fraisDInfrastructure,
    identificationDuLotPh,
    nombreDePi2,
    prixAuPi2,
    statutDesPromessesDAchat,
    amount,
    setDealName,
    setFraisDInfrastructure,
    setIdentificationDuLotPh,
    setNombreDePi2,
    setPrixAuPi2,
    setStatutDesPromessesDAchat,
    setAmount,
    handleModalInput,
    handleClose,
    show,
    handleClearInputs,
    edit,
  } = props;
  const [dealNameError, setDealNameError] = useState("");
  // const [dealStage, setDealStage] = useState("");
  const [fraisDInfrastructureError, setFraisDInfrastructureError] =
    useState("");
  const [identificationDuLotPhError, setIdentificationDuLotPhError] =
    useState("");
  const [nombreDePi2Error, setNombreDePi2Error] = useState("");
  const [prixAuPi2Error, setPrixAuPi2Error] = useState("");
  const [statutDesPromessesDAchatError, setStatutDesPromessesDAchatError] =
    useState("");
  const [amountError, setAmountError] = useState("");

  // dealId: item.dealId,  // auto
  // pipelineId: properties?.pipeline?.value,  //auto
  // properties: {
  //   amount: properties?.amount?.value || "", // input
  //   dealname: properties?.dealname?.value || "",  //input
  //   dealstage: properties?.dealstage?.value || "", //dropdown
  //   frais_d_infrastructure:
  //     properties?.frais_d_infrastructure?.value || "",  //input
  //   identification_du_lot_ph:
  //     properties?.identification_du_lot_ph?.value || "",  input
  //   nombre_de_pi2: properties?.nombre_de_pi2?.value || "",  input
  //   pipeline: properties?.pipeline?.value || "",   //automatic
  //   prix_au_pi2: properties?.prix_au_pi2?.value || "",  //input
  //   statut_des_promesses_d_achat:
  //     properties?.statut_des_promesses_d_achat?.value || "", //dropdown
  //   depot_necessaire: properties?.depot_necessaire?.value || "", //not
  //   description: properties?.description?.value || "",  //not
  // }

  const handleAddEditDeal = () => {
    const stage = getStageValue(pipelineItem, statutDesPromessesDAchat);

    if (!dealName.trim()) {
      setDealNameError("Enter deal name");
    } else if (!fraisDInfrastructure.trim()) {
      setFraisDInfrastructureError("Enter frais d infrastructure");
    } else if (!identificationDuLotPh.trim()) {
      setIdentificationDuLotPhError("Enter identification du lot Ph");
    } else if (!nombreDePi2.trim()) {
      setNombreDePi2Error("Enter nombre de PI2");
    } else if (!prixAuPi2.trim()) {
      setPrixAuPi2Error("Enter prix au PI2");
    } else if (statutDesPromessesDAchat === "DEFAULT") {
      setStatutDesPromessesDAchatError("Select statut des promesses da chat");
    } else if (!amount.trim()) {
      setAmountError("Enter amount");
    } else {
      const timeNow = new Date().getTime();

      var obj = {
        dealId: edit
          ? edit?.item[1]?.dealId
          : Math.floor(10000000 + Math.random() * 90000000),
        pipelineId: pipelineItem.pipelineId,
        dealname_lower: dealName.toLowerCase() || "",
        properties: {
          amount: { value: amount, timestamp: timeNow },
          dealname: { value: dealName || "", timestamp: timeNow },
          dealstage: { value: stage || "", timestamp: timeNow },
          frais_d_infrastructure: {
            value: fraisDInfrastructure || "",
            timestamp: timeNow,
          },
          identification_du_lot_ph: {
            value: identificationDuLotPh || "",
            timestamp: timeNow,
          },
          nombre_de_pi2: { value: nombreDePi2 || "", timestamp: timeNow },
          pipeline: {
            value: pipelineItem.pipelineId || "",
            timestamp: timeNow,
          },
          prix_au_pi2: { value: prixAuPi2 || "", timestamp: timeNow },
          statut_des_promesses_d_achat: {
            value: statutDesPromessesDAchat || "",
            timestamp: timeNow,
          },
          depot_necessaire: { value: "", timestamp: timeNow },
          description: { value: "", timestamp: timeNow },
        },
      };

      handleClearInputs();
      clearErrors();
      handleClose();
      if (edit) {
        obj = { ...edit?.item[1], ...obj };
        var updates = {};
        updates[tables.deals + edit.item[0]] = obj;
        update(ref(rdb), updates);
      } else {
        const dealsGroups = ref(rdb, tables.deals);
        var newPostRef = push(dealsGroups);
        set(newPostRef, obj);
      }
    }
  };

  const handleInput = (e, type) => {
    type(e.target.value);
    handleModalInput(e, type);
    clearErrors();
  };

  const clearErrors = () => {
    setDealNameError("");
    setFraisDInfrastructureError("");
    setIdentificationDuLotPhError("");
    setNombreDePi2Error("");
    setPrixAuPi2Error("");
    setStatutDesPromessesDAchatError("");
    setAmountError("");
  };

  const renderInput = (itemName, value, title, error, setType) => {
    return (
      <Col lg={6}>
        <Form.Group className="mb-3">
          <Form.Label className="font-bold text-base">{title}</Form.Label>
          <Form.Control
            type="text"
            name={itemName}
            value={value}
            onChange={(e) => handleInput(e, setType)}
            isInvalid={error}
            isValid={itemName}
          />
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </Form.Group>
      </Col>
    );
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-xl">
            {edit
              ? getLocalText("Phase.editLot")
              : getLocalText("Phase.addNewLot")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              {renderInput(
                "dealName",
                dealName,
                getLocalText("Phase.dealName"),
                dealNameError,
                setDealName
              )}

              {renderInput(
                "fraisDInfrastructure",
                fraisDInfrastructure,
                getLocalText("Phase.infrastructureCost"),
                fraisDInfrastructureError,
                setFraisDInfrastructure
              )}

              {renderInput(
                "identificationDuLotPh",
                identificationDuLotPh,
                getLocalText("Phase.identification_du_lot_ph"),
                identificationDuLotPhError,
                setIdentificationDuLotPh
              )}

              {renderInput(
                "nombreDePi2",
                nombreDePi2,
                getLocalText("Phase.numberdePI2"),
                nombreDePi2Error,
                setNombreDePi2
              )}

              {renderInput(
                "prixAuPi2",
                prixAuPi2,
                getLocalText("Phase.prixauPI2"),
                prixAuPi2Error,
                setPrixAuPi2
              )}

              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="font-bold text-base">
                    {getLocalText("Home.status")}
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    className="px-2 text-base select-placeholder"
                    // defaultValue={"DEFAULT"}
                    value={statutDesPromessesDAchat}
                    isInvalid={statutDesPromessesDAchatError}
                    isValid={statutDesPromessesDAchat}
                    onChange={(e) =>
                      handleInput(e, setStatutDesPromessesDAchat)
                    }
                  >
                    <option disabled value="DEFAULT">
                      {getLocalText("Home.chooseFromChoice")}
                    </option>
                    <option value=""></option>
                    <option value={constant.row1}>{constant.row1}</option>
                    <option value={constant.row2}>{constant.row2}</option>
                    <option value={constant.complete}>
                      {constant.complete}
                    </option>
                    <option value={constant.nonDisponible}>
                      {constant.nonDisponible}
                    </option>
                    <option value={constant.disponible}>
                      {constant.disponible}
                    </option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {statutDesPromessesDAchatError}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              {renderInput(
                "amount",
                amount,
                getLocalText("Phase.amount"),
                amountError,
                setAmount
              )}
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className="justify-content-start">
          <Button variant="green" onClick={handleAddEditDeal}>
            {edit ? getLocalText("Home.edit") : getLocalText("Home.add")}
          </Button>
          <Button
            variant="outline-green"
            onClick={() => {
              handleClose();
              handleClearInputs();
              clearErrors();
            }}
          >
            {getLocalText("Home.cancel")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddNewLot;

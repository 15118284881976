import React from "react";
import { Table, Form } from "react-bootstrap";
import { FiEdit } from "react-icons/fi";
// import { BsArrowRight } from "react-icons/bs";
// import { VscTrash } from "react-icons/vsc";
import moment from "moment";
import { getLocalText } from "../../locales/i18nextInit";
import { ref, update } from "firebase/database";
import { rdb, tables } from "../../services/config";

function UsersTable(props) {
  const { handleUpdateStatus, contactData } = props;
  const headerData = [
    "#",
    getLocalText("Home.name"),
    getLocalText("Auth.email"),
    getLocalText("Users.phoneNumber"),
    getLocalText("Users.category"),
    getLocalText("Home.createdDate"),
    "",
  ];

  return (
    <>
      <Table responsive className="mb-5 data-table">
        <thead className="border-top border-light-white">
          <tr>
            {headerData.map((item, index) => {
              return (
                <th
                  key={index.toString()}
                  className="text-base text-blue text-capitalize"
                >
                  {item}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {contactData
            ? Object.entries(contactData).map(([key, value], index) => {
                return (
                  <tr key={index.toString()}>
                    <td className="font-bold text-base text-blue">
                      {index + 1}
                    </td>
                    <td className="text-base text-blue">
                      {value[1]?.name +
                        (value[1]?.name ? " " : "") +
                        value[1]?.lastname}
                    </td>
                    <td className="text-base text-blue">{value[1]?.email}</td>
                    <td className="text-base text-blue">
                      {value[1]?.phoneNumber}
                    </td>
                    <td className="text-blue">
                      <Form.Select
                        aria-label="Default select example"
                        className="px-2 text-base select-placeholder w-72"
                        // defaultValue={"DEFAULT"}
                        value={value[1]?.category}
                        onChange={(e) => {
                          var obj = {
                            ...value[1],
                            category: e.target.value,
                          };
                          var updates = {};
                          updates[tables.contacts + value[0]] = obj;
                          update(ref(rdb), updates);
                          handleUpdateStatus(index, e.target.value);
                        }}
                      >
                        <option disabled value="DEFAULT">
                          {getLocalText("Users.selectCategory")}
                        </option>
                        <option value="Admin">Admin</option>
                        <option value="Normal">Normal</option>
                        <option value="Blocked">Blocked</option>
                      </Form.Select>
                    </td>
                    <td className="text-base text-blue">
                      {moment(value[1].createdDate).format("DD MMM YYYY HH:MM")}
                    </td>
                    <td className="text-base text-end">
                      <a
                        href={() => false}
                        onClick={() => props.handleEdit(value, index, key)}
                        className="text-blue cursor-pointer"
                      >
                        <FiEdit />
                      </a>
                      {/* <a onClick={() => props.handleDelete(value, index, key)}>
                        <VscTrash className="mx-lg-5 mx-md-5 mx-2 text-pink cursor-pointer" />
                      </a> */}
                      {/* <a
                    className="pe-3 pe-md-2 pe-lg-0 text-blue"
                    href="/information"
                  >
                    <BsArrowRight />
                  </a> */}
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </Table>
    </>
  );
}
export default UsersTable;

// const [data, setData] = useState([
//   {
//     name: "Larry Mark",
//     email: "lmark@email.com",
//     phoneNumber: "321 123 548",
//     category: "",
//     createdDate: "05 Aout 2022",
//   },
//   {
//     name: "Larry Mark",
//     email: "lmark@email.com",
//     phoneNumber: "321 123 548",
//     category: "",
//     createdDate: "05 Aout 2022",
//   },
//   {
//     name: "Larry Mark",
//     email: "lmark@email.com",
//     phoneNumber: "321 123 548",
//     category: "",
//     createdDate: "05 Aout 2022",
//   },
//   {
//     name: "Larry Mark",
//     email: "lmark@email.com",
//     phoneNumber: "321 123 548",
//     category: "",
//     createdDate: "05 Aout 2022",
//   },
//   {
//     name: "Larry Mark",
//     email: "lmark@email.com",
//     phoneNumber: "321 123 548",
//     category: "",
//     createdDate: "05 Aout 2022",
//   },
//   {
//     name: "Larry Mark",
//     email: "lmark@email.com",
//     phoneNumber: "321 123 548",
//     category: "",
//     createdDate: "05 Aout 2022",
//   },
// ]);

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Image, Col, Row, Form, Card } from "react-bootstrap";
import { ref, onValue } from "firebase/database";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { rdb, auth, tables } from "../services/config";
import { getLocalText } from "../locales/i18nextInit";
import { emailRegex, passwordRegex } from "../utils/validation";
import { Logo } from "../assets/images";
import { PageLoader } from "../components";

// admin@investiir.com
// 12345678
// pine-hill@yopmail.com
// Pine-hill123
const initialvalue = {
  email: "",
  password: "",
};
const Login = (props) => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [data, setData] = useState(initialvalue);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors((data[name] = ""));
    setData({ ...data, [name]: value });
  };

  const validation = () => {
    var error = {},
      valid = true;
    if (!data.email.trim()) {
      error.email = getLocalText("ErrorMessages.emptyEmail");
      valid = false;
    } else if (!data.email.match(emailRegex)) {
      error.email = getLocalText("ErrorMessages.invalidEmail");
      valid = false;
    } else if (!data.password.trim()) {
      error.password = getLocalText("ErrorMessages.emptyPassword");
      valid = false;
    } else if (!data.password.match(passwordRegex)) {
      error.password = getLocalText("ErrorMessages.invalidPassword");
      valid = false;
    }
    return { error, valid };
  };

  const handleLogin = async (e) => {
    setLoading(true);
    e.preventDefault();
    var { error, valid } = await validation();
    setErrors(error);
    if (valid) {
      signInWithEmailAndPassword(auth, data.email, data.password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          const starCountRef = ref(rdb, `${tables.users}${user.uid}`);
          onValue(starCountRef, (snapshot) => {
            const usersData = snapshot.val() || {};
            if (usersData?.type === "admin") {
              localStorage.setItem("@loginUserData", JSON.stringify(usersData));
              localStorage.setItem("@userUID", user.uid);
              localStorage.setItem("isLogin", true);
              setLoading(false);
              navigate("/home");
            } else {
              signOut(auth);
              alert("You are not admin");
              setLoading(false);
            }
          });
        })
        .catch((error) => {
          // const errorCode = error.code;
          // const errorMessage = error.message;
          setLoading(false);
          if (error.code === "auth/email-already-in-use") {
            alert(getLocalText("ErrorMessages.emailAlreadyInUse"));
          }
          if (error.code === "auth/invalid-email") {
            alert(getLocalText("ErrorMessages.invalidEmail"));
          }
          if (error.code === "auth/user-not-found") {
            alert(getLocalText("ErrorMessages.userNotFound"));
          }
          if (error.code === "auth/wrong-password") {
            alert(getLocalText("ErrorMessages.wrongPassword"));
          }
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="p-5 p-lg-0 min-h-lg-screen bg-black-white">
      {loading && <PageLoader />}
      <div className="d-flex justify-content-center">
        <Col
          lg={6}
          className="min-h-lg-screen py-lg-10 px-lg-20 pt-12 pb-8 position-relative"
        >
          <Row>
            <Col lg={10} md={12} className="mx-auto">
              <div className="text-center mb-16">
                <a href={() => false}>
                  <Image className="header-logo shadow-b" src={Logo} />
                </a>
              </div>
              <Card className="shadow-a p-10">
                <div className="px-0 pt-0 border-bottom card-header">
                  <h3>{getLocalText("Auth.hello") + "!"}</h3>
                </div>
                <Card.Body className="px-0 pb-0">
                  <Form>
                    <Form.Group className="mb-3">
                      <Form.Label className="font-bold text-base">
                        {getLocalText("Auth.email")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="email"
                        placeholder={getLocalText("Auth.enterHere")}
                        onChange={(e) => handleChange(e)}
                        value={data.email}
                        isInvalid={errors.email}
                        isValid={data.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="font-bold text-base">
                        {getLocalText("Auth.password")}
                      </Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        placeholder={getLocalText("Auth.enterHere")}
                        onChange={(e) => handleChange(e)}
                        value={data.password}
                        isInvalid={errors.password}
                        isValid={data.password}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <div>
                      <a
                        href={() => false}
                        className="link border-darkblue"
                        onClick={() => navigate("/forgot-password")}
                      >
                        {getLocalText("Auth.forgotPassword")}
                      </a>
                    </div>
                    <div>
                      <a
                        href={() => false}
                        className="btn-green btn mt-5 font-bold text-base"
                        onClick={handleLogin}
                      >
                        {getLocalText("Auth.login")}
                      </a>
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </div>
    </div>
  );
};
export default Login;

import React from "react";
import { Container, Pagination } from "react-bootstrap";
import { getLocalText } from "../../locales/i18nextInit";
import "./style.scss";

function TablePagination({ handleNext, handlePrevious, currentPage = 1 }) {
  return (
    <section className="py-4 mt-auto">
      <Container fluid={window.innerWidth > 1600 && true}
        className={window.innerWidth > 1600 ? "px-16" : ""}>
        <Pagination className="table-pagination justify-content-end align-items-center gap-2">
          <Pagination.Item onClick={handlePrevious}>
            {getLocalText("Home.previous")}
          </Pagination.Item>
          <Pagination.Item active>{currentPage}</Pagination.Item>
          <Pagination.Item onClick={handleNext}>
            {getLocalText("Home.next")}
          </Pagination.Item>
        </Pagination>
      </Container>
    </section>
  );
}
export default TablePagination;

import React from "react";
import { Table } from "react-bootstrap";
import { FiEdit } from "react-icons/fi";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { getLocalText } from "../locales/i18nextInit";

function CompaniesTable(props) {
  const { companiesData } = props;
  const navigate = useNavigate();
  const headerData = [
    "#",
    getLocalText("Home.name"),
    getLocalText("Users.phoneNumber"),
    "",
  ];

  return (
    <>
      <Table responsive className="mb-5 data-table">
        <thead className="border-top border-light-white">
          <tr>
            {headerData.map((item, index) => {
              return (
                <th
                  key={index.toString()}
                  className="text-base text-blue text-capitalize"
                >
                  {item}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {companiesData
            ? Object.entries(companiesData).map(([key, value], index) => {
                return (
                  <tr key={index.toString()}>
                    <td className="font-bold text-base text-blue">
                      {index + 1}
                    </td>
                    <td className="text-base text-blue">{value[1]?.name}</td>
                    <td className="text-base text-blue">{value[1]?.phone}</td>
                    <td className="text-base text-end">
                      <a
                        href={() => false}
                        onClick={() => props.handleEdit(value, index, key)}
                        className="text-blue cursor-pointer"
                      >
                        <FiEdit />
                      </a>
                      {/* <a onClick={() => props.handleDelete(value, index, key)}>
                        <VscTrash className="mx-lg-5 mx-md-5 mx-2 text-pink cursor-pointer" />
                      </a> */}
                      <a
                        href={() => false}
                        className="pe-3 ms-md-5 pe-md-2 pe-lg-0 text-blue"
                        // href="/information"
                        onClick={() =>
                          navigate("/users", {
                            state: { item: value[1], key, isCompanyData: true },
                          })
                        }
                      >
                        <BsArrowRight />
                      </a>
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </Table>
    </>
  );
}
export default CompaniesTable;

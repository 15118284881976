import React from "react";
import { Modal, Button } from "react-bootstrap";

function DeleteConfirmModal(props) {
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="md"
    >
      <Modal.Body>Are you sure you want to delete?</Modal.Body>
      <Modal.Footer>
        <Button variant="outline-green" onClick={props.handleClose}>
          No
        </Button>
        <Button variant="green" onClick={props.handleConfirm}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteConfirmModal;

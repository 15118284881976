// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-pagination .page-item .page-link {
  border-radius: 4px;
  font-size: 1rem;
  color: #ABB5BE;
}
.table-pagination .page-item.active .page-link {
  background-color: #e58888;
  border-color: #e58888;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/pagination/style.scss","webpack://./src/assets/scss/variable.scss"],"names":[],"mappings":"AAIQ;EACI,kBAAA;EACA,eAAA;EACA,cAAA;AAHZ;AAMY;EACI,yBCLV;EDMU,qBCNV;EDOU,WCTT;ADKP","sourcesContent":["@import \"../../assets/scss/variable.scss\";\n\n.table-pagination {\n    .page-item {\n        .page-link{\n            border-radius: 4px ;\n            font-size: 1rem;\n            color: #ABB5BE;\n        }\n        &.active {\n            .page-link {\n                background-color: $pink;\n                border-color: $pink;\n                color: $white;\n            }\n        }\n    }\n}","$blue: #152536;\n$green: #8ea385;\n$darkblue: #20232c;\n$light-white:#DEE2E6;\n$white:#fff;\n$gray:#808080;\n$pink:#e58888;\n$bright-blue:#006BFF;\n$ceramic:#F8F9FA;\n$green-grey:#7C8D75;\n$lightGrey:#D6D6D6;\n$pastelGrey:#CFCFCF;\n$stormGrey:#6C757D;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

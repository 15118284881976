import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

export const IS_STAGING = false;

const firebaseConfig = IS_STAGING
  ? {
      apiKey: process.env.REACT_APP_API_KEY_STAGING,
      authDomain: process.env.REACT_APP_AUTH_DOMAIN_STAGING,
      projectId: process.env.REACT_APP_PROJECT_ID_STAGING,
      storageBucket: process.env.REACT_APP_STORAGE_BUCKET_STAGING,
      messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_STAGING,
      appId: process.env.REACT_APP_APPID_STAGING,
      measurementId: process.env.REACT_APP_MEASUREMENT_ID_STAGING,
    }
  : {
      apiKey: process.env.REACT_APP_API_KEY,
      authDomain: process.env.REACT_APP_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_PROJECT_ID,
      storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_APPID,
      measurementId: process.env.REACT_APP_MEASUREMENT_ID,
    };

export const tables = IS_STAGING
  ? {
      // Tables Satging
      projects: "projectsStaging/",
      deals: "dealsStaging/",
      contacts: "contactsStaging/",
      companies: "companiesStaging/",
      users: "usersStaging/",
    }
  : {
      // Tables Production
      projects: "projects/",
      deals: "deals/",
      contacts: "contacts/",
      companies: "companies/",
      users: "users/",
    };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
getAnalytics(app);
export const db = getFirestore(app);
export const rdb = getDatabase(app);

import PageLoader from "./loader";
import Header from "./header/header";
import HomeTable from "./home-table";
import TablePagination from "./pagination/pagination";
import Footer from "./footer/footer";
import AddMapModel from "./add-map-model";
import PhaseTable from "./phase-table";
import PhaseFilter from "./phase-filter";
import AddNewLot from "./add-new-lot";
import AddNewUser from "./user/add-new-user";
import InformationFrom from "./information/information-form";
import UsersTable from "./user/user-table";
import AssociationTable from "./association-table";
import DeleteConfirmModal from "./confirm-delete";
import RecentActivities from "./recentActivities/recentActivities.js";
import CompaniesTable from "./companies-table";
import AddCompanyModel from "./add-company-model";
import SyncLoader from "./syncLoader";
import Spinner from "./spinner";

export {
  PageLoader,
  Header,
  HomeTable,
  TablePagination,
  Footer,
  AddMapModel,
  PhaseTable,
  PhaseFilter,
  AddNewLot,
  AddNewUser,
  InformationFrom,
  UsersTable,
  AssociationTable,
  DeleteConfirmModal,
  RecentActivities,
  CompaniesTable,
  AddCompanyModel,
  SyncLoader,
  Spinner,
};

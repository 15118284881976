import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { getLocalText } from "../../locales/i18nextInit";

function Footer() {
  return (
    <footer className="bg-light-white py-5 mt-auto">
      <Container fluid={window.innerWidth > 1600 && true}
        className={window.innerWidth > 1600 ? "px-16" : ""}>
        <Row>
          <Col sm={6}>
            <p className="text-uppercase">
              {/* {getLocalText("Home.domainPineHill")} */}
            </p>
          </Col>
          <Col sm={6}>
            <p className="text-sm-end font-bold">
              2022 - {getLocalText("Home.allRightsReserved")}
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;

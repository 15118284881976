import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
// import { HiArrowLeft } from "react-icons/hi";
import { Footer, AssociationTable, Header } from "../components";
import { getLocalText } from "../locales/i18nextInit";

function Users() {
  const location = useLocation();
  // const navigate = useNavigate();
  const associationData = [
    { name: getLocalText("Home.contacts") },
    { name: getLocalText("Home.companies") },
  ];

  return (
    <>
      <section className="vstack min-h-screen">
        <Header />
        <section className="bg-darkblue py-10">
          <Container fluid={window.innerWidth > 1600 && true}
            className={window.innerWidth > 1600 ? "px-16 position-static" : "position-static"}>
            <Row className="gy-5">
              <Col lg={6} md={4}>
                <h1 className="text-white">
                  {/* <a onClick={() => navigate("/phase")}>
                    <HiArrowLeft className="me-3 text-white" />
                  </a> */}
                  {getLocalText("Home.associations")}{" "}
                </h1>
                {location?.state?.item?.properties?.dealname?.value || ""}
              </Col>
            </Row>
          </Container>
        </section>
        <div className="vstack">
          <Container fluid={window.innerWidth > 1600 && true}
            className={window.innerWidth > 1600 ? "px-16" : ""}>
            <AssociationTable
              associationData={associationData}
              location={location}
            />
          </Container>
        </div>
        <Footer />
      </section>
    </>
  );
}
export default Users;

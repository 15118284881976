import React, { useEffect, useState } from "react";
import { Table, Form } from "react-bootstrap";
import moment from "moment";
import { FiEdit } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import { VscTrash } from "react-icons/vsc";
import { getLocalText } from "../locales/i18nextInit";
import { ref, update } from "firebase/database";
import { rdb, tables } from "../services/config";

function HomeTable(props) {
  const navigate = useNavigate();
  const [lotCount, setLotCount] = useState([]);

  useEffect(() => {
    var newCount = [];
    for (let index = 0; index < props.projectsData.length; index++) {
      const element = props.projectsData[index];
      var newItem = [];
      newItem = props.dealsData.filter(
        (item, index) => item[1].pipelineId === element[1].pipelineId
      );
      newCount.push(newItem.length);
    }
    setLotCount(newCount);
  }, [props.projectsData, props.dealsData]);

  const headerData = [
    "#",
    getLocalText("Home.name"),
    getLocalText("Home.lotNumber"),
    getLocalText("Home.createdDate"),
    getLocalText("Home.dateAndTime"),
    getLocalText("Home.hubspotSync"),
    getLocalText("Home.status"),
    "",
  ];

  return (
    <>
      <Table responsive className="mb-5 data-table">
        <thead className="border-top border-light-white">
          <tr>
            {headerData.map((item, index) => {
              return (
                <th
                  key={index.toString()}
                  className="text-base text-blue text-capitalize"
                >
                  {item}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {props?.projectsData
            ? Object.entries(props.projectsData).map(([key, value], index) => {
                return (
                  <tr key={index.toString()}>
                    <td className="font-bold text-base text-blue">
                      {index + 1}
                    </td>
                    <td className="text-base text-blue">{value[1].label}</td>
                    <td className="text-base text-blue">{lotCount[index]}</td>

                    <td className="text-base text-blue">
                      {value[1].createdAt
                        ? moment(value[1].createdAt).format("DD MMM YYYY HH:MM")
                        : ""}
                      {/* hh:MM a */}
                    </td>

                    <td className="text-base text-blue">
                      {value[1].date_limit
                        ? moment(value[1].date_limit).format("DD MMM YYYY") +
                          " " +
                          new Date(value[1].time_limit).getHours() +
                          ":" +
                          new Date(value[1].time_limit).getMinutes()
                        : ""}
                    </td>

                    <td className="text-base text-blue">
                      {moment(new Date()).format("DD MMM YYYY HH:MM")}
                    </td>
                    <td className="text-blue">
                      <Form.Select
                        aria-label="Default select example"
                        className="px-2 text-base select-placeholder w-56"
                        // defaultValue={"DEFAULT"}
                        value={value[1].status}
                        onChange={(e) => {
                          var obj = {
                            ...value[1],
                            status: e.target.value,
                          };
                          var updates = {};
                          updates[tables.projects + value[0]] = obj;
                          update(ref(rdb), updates);
                        }}
                      >
                        <option disabled value="DEFAULT">
                          {getLocalText("Home.selectHere")}
                        </option>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </Form.Select>
                    </td>
                    <td className="text-base text-end">
                      <a
                        href={() => false}
                        className="text-blue cursor-pointer"
                        onClick={() => props.handleEdit(value, index, key)}
                        style={{
                          pointerEvents:
                            value[1].status === "Inactive" ? "none" : "auto",
                          opacity: value[1].status === "Inactive" ? 0.4 : 1,
                        }}
                      >
                        <FiEdit className="mx-lg-5 mx-md-5 mx-2" />
                      </a>
                      {/* <a
                        href={() => false}
                        className="cursor-pointer"
                        onClick={() => props.handleDelete(value, index, key)}
                        style={{
                          pointerEvents:
                            value[1].status === "Inactive" ? "none" : "auto",
                          opacity: value[1].status === "Inactive" ? 0.4 : 1,
                        }}
                      >
                        <VscTrash className="mx-lg-5 mx-md-5 mx-2 text-pink" />
                      </a> */}
                      <a
                        href={() => false}
                        className="pe-3 pe-md-2 pe-lg-0 text-blue cursor-pointer"
                        onClick={() => {
                          navigate("/phase", {
                            state: { item: value },
                          });
                        }}
                        style={{
                          pointerEvents:
                            value[1].status === "Inactive" ? "none" : "auto",
                          opacity: value[1].status === "Inactive" ? 0.4 : 1,
                        }}
                      >
                        <BsArrowRight />
                      </a>
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </Table>
    </>
  );
}
export default HomeTable;

// const [data, setData] = useState([
//   {
//     name: "Phase #01",
//     lotNumber: 26,
//     createdDate: "12 Sep 2022",
//     hubspotSync: "12 Sep 2022",
//     status: "",
//   },
//   {
//     name: "Phase #01",
//     lotNumber: 26,
//     createdDate: "12 Sep 2022",
//     hubspotSync: "12 Sep 2022",
//     status: "",
//   },
//   {
//     name: "Phase #01",
//     lotNumber: 26,
//     createdDate: "12 Sep 2022",
//     hubspotSync: "12 Sep 2022",
//     status: "",
//   },
//   {
//     name: "Phase #02",
//     lotNumber: 26,
//     createdDate: "12 Sep 2022",
//     hubspotSync: "12 Sep 2022",
//     status: "",
//   },
//   {
//     name: "Phase #03",
//     lotNumber: 26,
//     createdDate: "12 Sep 2022",
//     hubspotSync: "12 Sep 2022",
//     status: "",
//   },
//   {
//     name: "Phase #04",
//     lotNumber: 26,
//     createdDate: "12 Sep 2022",
//     hubspotSync: "12 Sep 2022",
//     status: "",
//   },
//   {
//     name: "Phase #05",
//     lotNumber: 26,
//     createdDate: "12 Sep 2022",
//     hubspotSync: "12 Sep 2022",
//     status: "",
//   },
// ]);
